import React, {
    useEffect, useState
} from "react";
import { locations } from "../../../constants";
import api from "../../../api";
import { toast } from "react-toastify";
import {
    useSelector, useDispatch
} from "react-redux";
import { getUser } from "../../../redux/slices/applicationSlice.js";
import { getUserFetch } from "../../../redux/thunks/applicationThunks.js";

const LocationInstructr = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const [searchString, setSearchString] = useState("");
    const [locationsData, setLocationsData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const locationsFiltered = locationsData?.filter((item) => item.title.toLowerCase().includes(searchString.toLowerCase()));

    const saveInstructorLocations = async () => {
        const res = await api.profiles.updateInstructor({ locations: selectedLocation });

        if (res.success) {
            toast.success(res?.message || "Locations Saved Successfully");
            await dispatch(getUserFetch());
        } else {
            toast.error(res?.message || "Locations Saved Error");
        }
    };

    useEffect(() => {
        setLocationsData(locations?.map((item) => ({
            ...item,
            open: false,
            list: item.list.map((itemL) => ({
                ...itemL,
                open: false
            }))
        })));
    }, []);
    useEffect(() => {
        if (user?.profile?.locations) {
            setSelectedLocation(user?.profile?.locations);
        }
    }, [user]);

    return (
        <div className="settings-body gutters-form--gap">
            <div className="sidebar-header heading-row">
                <div className="heading-block">
                    <h3 className="heading">
                        Set your location availability
                    </h3>

                    <p className="desk-heading --sm-heading">
                        Select regions or countries where you are available to work.
                    </p>
                </div>
            </div>

            <form
                className="form form-settings"
                method="post"
            >
                <div className="form-body">
                    <div className="form-items-list">
                        <div className="form-item">
                            <div className="row gutters-form-5">
                                <div className="col-12">
                                    <div className="form-group input--md input--icon-left">
                                        <div className="input-wrapper">
                                            <span className="ico color-text">
                                                <svg
                                                    fill="none"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    width="22"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="10.1365"
                                                        cy="10.1365"
                                                        r="6.47345"
                                                        stroke="CURRENTCOLOR"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    />

                                                    <path
                                                        d="M18.3362 18.3362L14.7139 14.7139"
                                                        stroke="CURRENTCOLOR"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>

                                            <input
                                                className="input input--solid"
                                                onInput={(e) => {
                                                    setSearchString(e.target.value);
                                                }}
                                                placeholder="Search location..."
                                                type="text"
                                                value={searchString}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    { locationsFiltered?.map((item, index) => {
                                        return (
                                            <div
                                                className={`accordeon-single accordeon-checker ${item?.open
                                                    ? "active-accordeon"
                                                    : ""}`}
                                                key={index}
                                            >
                                                <div className="accordeon-single--btn accordeon-checker--btn">
                                                    <span
                                                        className="ico ico-single--ico"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setLocationsData((prev) => {
                                                                return [...prev]?.map((item2, i) => item2.key === item.key ? {
                                                                    ...item2,
                                                                    open: !item2.open 
                                                                }: item2);
                                                            });
                                                        }}
                                                    >
                                                        <img src={require("../../../assets/img/ico-arrow--drp.svg").default} />
                                                    </span>

                                                    <label className="check-btn check-group-btn justify-start">
                                                        <input
                                                            checked={selectedLocation?.includes(item.key)}
                                                            hidden={true}
                                                            onChange={(e) => {
                                                                if (!selectedLocation?.includes(item.key)) {
                                                                    setSelectedLocation((prev) => [...prev, item.key]);
                                                                } else {
                                                                    setSelectedLocation((prev) => prev.filter((itemF) => itemF !== item.key));
                                                                }
                                                            }}
                                                            type="checkbox"
                                                        />

                                                        <span className="check" />

                                                        <p className="check-text">
                                                            { item.title }
                                                        </p>
                                                    </label>
                                                </div>

                                                { item?.open
                                                    ? (
                                                        <div className="accordeon-single--body accordeon-checker--body">
                                                            { item?.list?.map((subItem, subItemIndex) => {
                                                                return (
                                                                    <div
                                                                        className={`accordeon-single accordeon-checker ${subItem?.open
                                                                            ? "active-accordeon"
                                                                            : ""}`}
                                                                        key={subItemIndex}
                                                                    >
                                                                        <div className="accordeon-single--btn accordeon-checker--btn">
                                                                            { subItem?.list
                                                                                ? (
                                                                                    <span
                                                                                        className="ico ico-single--ico"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            setLocationsData((prev) => {
                                                                                                return [...prev]?.map((item2, i) => (item2.key === item.key ? {
                                                                                                    ...item2,
                                                                                                    list: item2.list?.map((itemList, itemListIndex) => itemListIndex === subItemIndex ? {
                                                                                                        ...itemList,
                                                                                                        open: !itemList.open 
                                                                                                    } : itemList) 
                                                                                                } : item2));
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        <img src={require("../../../assets/img/ico-arrow--drp.svg").default} />
                                                                                    </span>
                                                                                )


                                                                                : null }

                                                                            <label className="check-btn check-group-btn justify-start">
                                                                                <input
                                                                                    checked={selectedLocation?.includes(subItem?.key)}
                                                                                    hidden={true}
                                                                                    onChange={(e) => {
                                                                                        if (!selectedLocation?.includes(subItem?.key)) {
                                                                                            setSelectedLocation((prev) => [...prev, subItem?.key]);
                                                                                        } else {
                                                                                            setSelectedLocation((prev) => prev.filter((itemF) => itemF !== subItem?.key));
                                                                                        }
                                                                                    }}
                                                                                    type="checkbox"
                                                                                />

                                                                                <span className="check" />

                                                                                <p className="check-text">
                                                                                    { subItem?.title }
                                                                                </p>
                                                                            </label>
                                                                        </div>

                                                                        { subItem?.open
                                                                            ? (
                                                                                <div className="accordeon-single--body accordeon-checker--body">
                                                                                    <ul className="checkers-list">
                                                                                        { subItem?.list?.map((subSubItem, subSubItemIndex) => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <label
                                                                                                        className="check-btn check-group-btn justify-start"
                                                                                                        key={subSubItemIndex}
                                                                                                    >
                                                                                                        <input
                                                                                                            checked={selectedLocation?.includes(subSubItem?.key)}
                                                                                                            hidden={true}
                                                                                                            onChange={(e) => {
                                                                                                                if (!selectedLocation?.includes(subSubItem?.key)) {
                                                                                                                    setSelectedLocation((prev) => [...prev, subSubItem?.key]);
                                                                                                                } else {
                                                                                                                    setSelectedLocation((prev) => prev.filter((itemF) => itemF !== subSubItem?.key));
                                                                                                                }
                                                                                                            }}
                                                                                                            type="checkbox"
                                                                                                        />

                                                                                                        <span className="check" />

                                                                                                        <p className="check-text">
                                                                                                            { subSubItem?.title }
                                                                                                        </p>
                                                                                                    </label>
                                                                                                </li>
                                                                                            );
                                                                                        }) }
                                                                                    </ul>
                                                                                </div>
                                                                            )


                                                                            : null }
                                                                    </div>
                                                                );
                                                            }) }
                                                        </div>
                                                    )


                                                    : null }
                                            </div>
                                        );
                                    }) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-footer">
                    <div className="flex-group justify-end">
                        <button
                            className="btn btn--primary btn--sm"
                            onClick={(e) => {
                                e.preventDefault();
                                saveInstructorLocations();
                            }}
                            type="submit"
                        >
                            <span>
                                Save Changes
                            </span>
                        </button>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default LocationInstructr;
