import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SeminarTemplate } from "../parts/SeminarTemplate";
import { getAllSeminarsWithFiltersFetch } from "../../../redux/thunks/seminarsThunks";
import { Country } from "country-state-city";
import {
    belts, languages, trainingStyle
} from "../../../constants";
import { Trans, useTranslation } from "react-i18next";

const SeminarsPendingByInstructor = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const { seminars, pagination, isLoading } = useSelector((state) => state.seminars);
    const [filters] = useState({
        sort_by: "",
        sort_as: "desc",
        gender: "",
        location: "",
        price: "",
        trainingStyle: "",
        belt: "",
        language: "",
        search: "",
        page: "",
        limit: ""
    });
    const allCountries = Country.getAllCountries();
    const locationOptions = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const priceOptions = [
        "$$$$$",
        "$$$$",
        "$$$",
        "$$",
        "$"
    ].map((item) => ({
        label: item,
        value: item
    }));
    const trainingStyleOptions = trainingStyle.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const beltOptions = belts.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const languageOptions = languages.map(({ title, key }) => ({
        label: title,
        value: title
    }));
    const sortOptions = [
        {
            label: t('date'),
            value: "id"
        }, {
            label: t('price'),
            value: "seminarMinPrice"
        }
    ];
    const filtersData = [
        {
            label: t('location'),
            query: "location",
            options: locationOptions,
            all: true
        },
        {
            label: t('seminar_price'),
            query: "price",
            options: priceOptions,
            all: true
        },
        {
            label: t('gi'),
            query: "trainingStyle",
            options: trainingStyleOptions,
            all: true
        },
        {
            label: t('belt'),
            query: "belt",
            options: beltOptions
        },
        {
            label: t('lang'),
            query: "language",
            options: languageOptions,
            all: true
        }
    ];

    return (
        <SeminarTemplate
            baseQuery="status=pending&role=instructor"
            fethFuntion={getAllSeminarsWithFiltersFetch}
            filterLinks={[
                {
                    label: t('seminars'),
                    link: "/planed-by-insctructor"
                }, {
                    label: t('camps'),
                    link: "/planed-by-insctructor/camps"
                }
            ]}
            filters={filters}
            filtersData={filtersData}
            firsScreenOptions={{
                title: (
                    <Trans i18nKey="seminars.pending_seminars_camps">
                        Pending <span className="color-primary">SEMINARS / Camps</span>
                    </Trans>
                ),
                description: t('pending_seminars_camps_descr'),
                placeholder: t('pending_seminars_placeholder'),
                imageBg: require("../../../assets/img/pending-seminars-bg.png")
            }}

            isLoading={isLoading}
            pageType="pending"
            pagination={pagination}
            seminars={seminars}
            sotrBy={sortOptions}
            name={t('seminars')}
        />
    );
};

export default SeminarsPendingByInstructor;
