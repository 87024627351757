import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthModalOpen } from "../../redux/slices/applicationSlice";
import { AuthModals } from "../modals/singUpModals/AuthProvider";
import Accordeon from "../ui/accordeons/Accordeon";
import Button from "../ui/buttons/Button";
import { useTranslation } from "react-i18next";
import findValue from "../../helpers/findValue";

const HowPlatformWork = ({ pageData }) => {
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'home' });
    const getValue = (key) => findValue(pageData?.blocks, key);
    const howPlanformWork = [
        {
            title: getValue('hpw_for_instructors_title') || t('for_instructors'),
            // description: pageData?.blocks?.[1]?.blockPresets?.[4]?.value || 'Submit a request to your chosen instructor to organize a personalized sports seminar. Customize the seminar options and design an engaging cover for the upcoming event. Manage ticket sales for your seminar and extend invitations to students for your bespoke Jiu-Jitsu seminar.',
            description: <>
                <ul style={{ marginLeft: 30 }}>
                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t('plan_publish')}
                        </span>

                        {" "}
                        {t('plan_publish_descr')}
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t('plan_tour')}
                        </span>

                        {" "}
                        {t('plan_tour_descr')}
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t('private_lessons')}
                        </span>

                        {" "}
                        {t('private_lessons_descr')}
                    </li>
                </ul>

                <p
                    style={{
                        fontWeight: "bold",
                        marginTop: 20
                    }}
                >
                    {t('steps')}
                </p>

                <ol style={{ marginLeft: 30 }}>
                    <li>
                        {t('create_your_profile')}
                    </li>

                    <li>
                        {t('plan_accept_seminar')}
                    </li>

                    <li>
                        {t('match_academy')}
                    </li>

                    <li>
                        {t('publish_promote')}
                    </li>

                    <li>
                        {t('keep_track')}
                    </li>
                </ol>
            </>,
            isActive: true
        }, {
            title: getValue('hpw_academics_title') || t('for_academies'),
            // description: pageData?.blocks?.[1]?.blockPresets?.[2]?.value || 'Submit a request to your chosen instructor to organize a personalized sports seminar. Customize the seminar options and design an engaging cover for the upcoming event. Manage ticket sales for your seminar and extend invitations to students for your bespoke Jiu-Jitsu seminar.',
            description: <>
                <ul style={{ marginLeft: 30 }}>
                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t('plan_publish')}
                        </span>

                        {" "}

                        {t('plan_academy')}
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t('stay_informed')}
                        </span>

                        {" "}

                        {t('stay_informed_descr')}
                    </li>
                </ul>

                <p
                    style={{
                        fontWeight: "bold",
                        marginTop: 20
                    }}
                >
                    {t('steps')}
                </p>

                <ol style={{ marginLeft: 30 }}>
                    <li>
                        {t('create_your_profile')}
                    </li>

                    <li>
                        {t('plan_accept_seminar')}
                    </li>

                    <li>
                        {t('match_instructor')}
                    </li>

                    <li>
                        {t('publish_promote')}
                    </li>

                    <li>
                        {t('keep_track')}
                    </li>
                </ol>
            </>,
            isActive: false
        }, {
            title: getValue('hpw_for_students_title') || t('for_students'),
            // description: pageData?.blocks?.[1]?.blockPresets?.[6]?.value || 'Submit a request to your chosen instructor to organize a personalized sports seminar. Customize the seminar options and design an engaging cover for the upcoming event. Manage ticket sales for your seminar and extend invitations to students for your bespoke Jiu-Jitsu seminar.',
            description: <>
                <ul style={{ marginLeft: 30 }}>
                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t('browse_book')}
                        </span>

                        {" "}

                        {t('browse_book_descr')}
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t('payment_confirmation')}
                        </span>

                        {" "}

                        {t('payment_confirmation_descr')}
                    </li>

                    <li style={{ listStyle: "outside" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t('private_lessons')}
                        </span>

                        {" "}

                        {t('want_personalized')}
                    </li>
                </ul>

                <p
                    style={{
                        fontWeight: "bold",
                        marginTop: 20
                    }}
                >
                    {t('steps')}
                </p>

                <ol style={{ marginLeft: 30 }}>
                    <li>
                        {t('browse_seminars')}
                    </li>

                    <li>
                        {t('select_reserve')}
                    </li>

                    <li>
                        {t('min_attendance')}
                    </li>

                </ol>
            </>,
            isActive: false
        }
    ];
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index
            ? null
            : index);
    };

    return (
        <section className="section">
            <div className="container">
                <h2
                    className="heading mb-100 text-center max-md-mb-40"
                    // dangerouslySetInnerHTML={{ __html: pageData?.blocks?.[1]?.blockPresets?.[0]?.value || "HOW Platform WORKS" }}
                >
                    {getValue('hpw_title') || t('how_works')}
                </h2>

                {/* <p className='accordeon-head-title mb-36'>
                BJJ academies and instructors can easily plan and promote their seminars and camps through the platform. Whether you're an academy looking to host a top-tier instructor or an instructor aiming to share your expertise with eager students, Plan Seminars makes it happen. Here's how it works:
                </p> */}
            </div>

            <div className="how-platform-row">
                <div className="how-platform-row__faq-block">
                    <div className="container">
                        <div className="how-platform-row__faq-block-body">
                            {howPlanformWork.map((item, index) => (
                                <Accordeon
                                    className="w-full"
                                    index={index}
                                    isActive={activeIndex === index}
                                    key={index}
                                    onClick={() => toggleAccordion(index)}
                                    onlyOneIsOpen={true}
                                    title={item.title}
                                >
                                    <div className="mb-36">
                                        {item.description}
                                    </div>

                                    <Button
                                        onClick={() => dispatch(setAuthModalOpen(AuthModals.singUp))}
                                        className="btn btn--primary btn--md"
                                    >
                                        <span className="info">
                                            {t('create_profile')}
                                        </span>

                                        <span className="ico ico-14">
                                            <svg
                                                width="19"
                                                height="18"
                                                viewBox="0 0 19 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.25 3.375L12.875 9L7.25 14.625"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="square"
                                                >
                                                </path>
                                            </svg>
                                        </span>
                                    </Button>

                                </Accordeon>
                            )



                            )}
                        </div>
                    </div>
                </div>

                <div className="how-platform-row-img">
                    <div className="how-platform-img h-full">
                        <div className="platform-bg-effect" />

                        <img
                            src={pageData?.blocks?.[1]?.blockPresets?.[7]?.value
                                ? process.env.REACT_APP_API_URL + pageData?.blocks?.[1]?.blockPresets?.[7]?.value
                                : require("../../assets/img/how-platform-squeeze.jpg")}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowPlatformWork;
