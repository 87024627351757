import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useLocation, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import Input from "../../../components/ui/formElements/Input";
import SelectMulty from "../../../components/ui/formElements/SelectMulty";
import Textarea from "../../../components/ui/formElements/Textarea";
import UploadVideos from "../../../components/ui/formElements/UploadVideos";
import PageLoader from "../../../components/ui/PageLoader";
import { teachingStyles } from "../../../constants";
import { convertObjectToFormData } from "../../../helpers/utils";
import SeminarPhotosUpload from "../parts/SeminarPhotosUpload";
import { resetSeminarFormData } from "../../../redux/slices/planningSlice";
import {
    createCampFetch, updateCampFetch
} from "../../../redux/thunks/planCampThuncks";
import HotelInfoSection from "../parts/HotelInfoSection";
import IncludedSection from "../parts/IncludedSection";
import PopularQuestions from "../parts/PopularQuestions";
import ScheduleDates from "../parts/ScheduleDates";
import { pick } from "lodash";
import { updateSeminarFetch } from "../../../redux/thunks/seminarsThunks";
import { toast } from "react-toastify";
import { format } from "date-fns";

const DesignText = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { chatId: paramsChatId, campId } = useParams();
    const chatId = paramsChatId === "null" ? null : paramsChatId;
    const { campDetails, isLoading: isCampDetailsLoading } = useSelector((state) => state.planCamps);
    const isActive = campDetails?.status === "active";
    const [editedFields, setEditedFields] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [coverImage, setCoverImage] = useState(null);
    const teachingStyleOptions = teachingStyles.map(({ key, title }) => ({
        value: key,
        label: title
    }));

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        setIsLoading(true);

        if (campId) {
            if (!errors.hasRequiredError) {
                const body = new FormData();
                const dataToUpdate = chatId || isActive ? pick(formData, editedFields) :
                    {
                        ...formData,
                        startAt: format(formData.startAt, "yyyy-MM-dd"),
                        endAt: format(formData.endAt, "yyyy-MM-dd")
                    };;

                convertObjectToFormData(dataToUpdate, body);
                let res;
                if (chatId) {
                    res = await dispatch(updateCampFetch({
                        campId,
                        body
                    })).unwrap();
                } else {
                    res = await dispatch(updateCampFetch({
                        campId,
                        body
                    })).unwrap();
                }


                if (res) {
                    if (chatId) {
                        navigate(`/chat/${chatId}`);
                    } else {
                        dispatch(resetSeminarFormData());
                        navigate(`/my-seminars/camps/${campId}`);
                        toast.success("Successfully updated")
                    }
                }
            }
        } else {
            if (!errors.hasRequiredError) {
                const body = new FormData();

                convertObjectToFormData(formData, body);
                const res = await dispatch(createCampFetch(body)).unwrap();

                if (res) {
                    dispatch(resetSeminarFormData());
                    navigate("../success");
                }
            }
        }

        setIsLoading(false);
    };

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    const handleChangePhotos = (photos) => {
        handleStepChange("photos", photos);
    };

    const handleChangeVideos = (videos) => {
        handleStepChange("videos", videos);
    };

    // useEffect(() => {
    //     handlePreviewChange("cover", coverImage)
    //     handleStepChange("coverImageName", coverImage?.name)
    // }, [coverImage]) 

    useEffect(() => {
        handlePreviewChange("cover", coverImage);
        const getCoverName = (cover) => cover?.name || cover?.originalname;

        if (coverImage?.id) {
            handleStepChange("coverImageId", coverImage?.id);

            return;
        }

        if (getCoverName(coverImage)) {
            handleStepChange("coverImageName", getCoverName(coverImage));
        } else if (formData.photos?.length) {
            handleStepChange("coverImageName", getCoverName(formData.photos[0]));
        } else {
            handleStepChange("coverImageName", "");
        }
    }, [coverImage]);

    useEffect(() => {
        setErrorConfig({
            requiredFields: [
                "traningStyle",
                "photos",
                "name",
                "teachingStyles",
                "summary",

                // "videos",
                // "hotelLinks",
                // "what_included", 
                "startAt",
                "endAt"

                // "schedule",
                // "faq", 
            ]
        });
    }, [formData]);

    useEffect(() => {
        if (location.state?.previousLocation === "/template-editor") {
            setEditedFields((prev) => [...prev, "photos"]);
            setCoverImage(formData.photos?.find(photo => photo.name === formData.coverImageName))
        }
    }, [location])

    return (
        <>
            {
                !isLoading || isCampDetailsLoading
                    ? (
                        <>
                            <SeminarPhotosUpload
                                coverImage={coverImage}
                                images={formData?.photos}
                                setCoverImage={setCoverImage}
                                setImages={handleChangePhotos}
                                title="Camp Photo(S)"
                            // error={isErrorsShown && errors.photos}
                            />

                            <hr className="hr mb-42" />

                            <h6 className="heading mb-32">
                                Camp Info
                            </h6>

                            <div className="row gx-16 py-24 mb-42">
                                <div className="col-12">
                                    <Input
                                        error={isErrorsShown && errors?.name}
                                        inputClasses="input--solid"
                                        label="Camp Name"
                                        onChange={(e) => handleStepChange("name", e.target.value)}
                                        placeholder="Add a name here..."
                                        value={formData?.name}
                                        wrapperClasses="input--lg pb-32 border-bottom"
                                    />
                                </div>

                                <div className="col-12">
                                    <SelectMulty
                                        error={isErrorsShown && errors?.teachingStyles}
                                        label="Teaching Style"
                                        onChange={(options) => handleStepChange("teachingStyles", options.map(({ value }) => value))}
                                        options={teachingStyleOptions}
                                        placeholder="Add teaching style..."
                                        value={formData.teachingStyles}
                                        wrapperClasses="select--outline select--outline-bg input--lg w-full pb-32 border-bottom"
                                    />
                                </div>

                                <div className="col-12">
                                    <Textarea
                                        error={isErrorsShown && errors?.summary}
                                        label="Summary"
                                        onChange={(e) => handleStepChange("summary", e.target.value)}
                                        placeholder="Please write summary here..."
                                        style={{ minHeight: 140 }}
                                        textareaClasses="input--solid"
                                        value={formData?.summary}
                                        wrapperClasses="input--lg"
                                    />
                                </div>

                                {
                                    campId && chatId && (
                                        <>
                                            <div className="col-12">
                                                <UploadVideos
                                                    error={isErrorsShown && errors?.videos}
                                                    setVideos={handleChangeVideos}
                                                    videos={formData?.videos}
                                                />
                                            </div>

                                            <div className="col-12">
                                                <HotelInfoSection
                                                    data={formData?.hotelLink}
                                                    errors={errors?.hotelLink}
                                                    handleChange={(data) => handleStepChange("hotelLink", data)}
                                                    isErrorsShown={isErrorsShown}
                                                />
                                            </div>

                                            <div className="col-12">
                                                <IncludedSection
                                                    data={formData?.what_included}
                                                    error={isErrorsShown && errors?.what_included}
                                                    handleChange={(data) => handleStepChange("what_included", data)}
                                                />
                                            </div>

                                            <div className="col-12">
                                                <div className="border-bottom mb-42 pb-42">
                                                    <label className="label mb-12">
                                                        Camp Schedule
                                                    </label>

                                                    <ScheduleDates
                                                        data={formData?.schedule}
                                                        endAt={formData?.endAt}
                                                        errors={errors?.schedule}
                                                        handleChange={(data) => handleStepChange("schedule", data)}
                                                        handleInit={(data) => handleChange("schedule", data)}
                                                        isErrorsShown={isErrorsShown}
                                                        startAt={formData?.startAt}
                                                    />
                                                </div>

                                                <PopularQuestions
                                                    data={formData.faq}
                                                    errors={errors?.faq}
                                                    handleChange={(data) => handleStepChange("faq", data)}
                                                    isErrorsShown={isErrorsShown}
                                                    eventType="plancamp"
                                                />

                                            </div>
                                        </>
                                    )}
                            </div>

                            <div className="page-action-group">
                                {
                                    isActive ? (
                                        <Link
                                            className="btn btn--default btn--sm"
                                            to={campId && `/my-seminars/camps/${campId}`}
                                        >
                                            Cancel
                                        </Link>
                                    ) : (
                                        <Link
                                            className="btn btn--default btn--sm"
                                            to={chatId && campId
                                                ? `/chat/${chatId}`
                                                : "../seminar-info"}
                                        >
                                            Cancel
                                        </Link>
                                    )
                                }

                                <button
                                    className={`btn btn--primary btn--sm ${isLoading
                                        ? "disabled"
                                        : ""}`}
                                    onClick={handleSubmit}
                                >
                                    <span className="info">
                                        {campId && chatId ? "Continue" : "Publish"}
                                    </span>

                                    {
                                        campId && chatId && (
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    width="14"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M5.25 2.625L9.625 7L5.25 11.375"
                                                        stroke="#02020A"
                                                        strokeLinecap="square"
                                                        strokeWidth="1.6"
                                                    />
                                                </svg>
                                            </span>
                                        )
                                    }
                                </button>
                            </div>
                        </>
                    )
                    : <PageLoader />
            }
        </>
    );
};

export default DesignText;
