import {
    useEffect, useState
} from "react";
import { Country } from "country-state-city";
import { pick } from "lodash";
import moment from "moment";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import { convertObjectToFormData, getFullLocation } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { updateCampFetch } from "../../../redux/thunks/planCampThuncks";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import { ReactComponent as InfoIcon } from "../../../assets/img/question-circle.svg";
import Input from "../../../components/ui/formElements/Input";
import { getAcademyGeneralInfo } from "../../../redux/slices/academyGeneralInfoSlice";

const TimeAndDate = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const { chatId: paramsChatId, campId } = useParams();
    const chatId = paramsChatId === "null" ? null : paramsChatId;

    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [editedFields, setEditedFields] = useState([]);
    const allCountries = Country.getAllCountries();
    
    const locationOptions = allCountries?.map((item) => ({
        value: item.isoCode,
        label: item.name
    })) || [];
    const [isAcademyLocation, setIsAcademyLocation] = useState(false);

    const getIncludedDates = (date) => {
        if (!date) {
            return null;
        }

        const includedDates = new Set();
        const currentDate = new Date(date);

        for (let i = 0; i <= 7; i++) {
            if (i === 0) {
                continue;
            }

            const newDate = new Date(currentDate);

            newDate.setDate(currentDate.getDate() + i);
            includedDates.add(newDate);
        }

        return Array.from(includedDates);
    };

    const handleSubmit = async () => {
        setIsErrorsShown(true);

        if (!errors.hasRequiredError) {
            if (campId && chatId) {
                const body = new FormData();
                const dataToUpdate = pick(formData, editedFields);

                convertObjectToFormData(dataToUpdate, body);
                const res = await dispatch(updateCampFetch({
                    campId,
                    body
                })).unwrap();

                if (res) {
                    navigate(`/chat/${chatId}`);
                }
            } else {
                navigate("seminar-info");
            }
        }
    };

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    useEffect(() => {
        setErrorConfig({ requiredFields: ["startAt", "endAt"] });
    }, [formData]);
    const academyInfo = useSelector(getAcademyGeneralInfo);

    useEffect(() => {
        if (user?.role === "academy" && academyInfo && isAcademyLocation) {
            handleChange("address", academyInfo?.addressLine);
            handleChange("country", academyInfo?.country);
            handlePreviewChange("location", getFullLocation(Country.getAllCountries(academyInfo?.country)?.name, academyInfo.city, academyInfo?.addressLine0));
        }
    }, [user, academyInfo, isAcademyLocation]);

    return (
        <>
            <h6 className="heading mb-36 max-w-238">
                Camp Location
            </h6>

            <div className="row gutters-form mb-36">
                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        disabled={isAcademyLocation}
                        error={isErrorsShown && errors.country}
                        label="Country"
                        onChange={(option) => handleStepChange("country", option.value)}
                        options={locationOptions}
                        placeholder="Select Location"
                        value={formData.country}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <Input
                        disabled={isAcademyLocation}
                        error={isErrorsShown && errors?.address}
                        inputClasses="input--solid"
                        label="Address (academy by deafult)"
                        onChange={(e) => handleStepChange("address", e.target.value)}
                        placeholder="Enter location here..."
                        value={formData.address}
                        wrapperClasses="input--lg"
                    />
                </div>

                {
                    user?.role === "academy" && (
                        <div className="col-12">
                            <div className="option-group ">
                                <div className="row-group">
                                    <CheckboxSwitch
                                        checked={!isAcademyLocation}
                                        onChange={() => {
                                            handleStepChange("location", null);
                                            handleStepChange("address", "");
                                            setIsAcademyLocation(!isAcademyLocation);
                                        }}
                                        text="The seminar will take place at a third party location."
                                    />
                                </div>
                            </div>
                        </div>
                    )}
            </div>

            <hr className="hr-horisontal mb-36" />

            <h6 className="heading mb-36 max-w-238">
                Camp DAte
            </h6>

            <div className="row gutters-form">

                <div className="col-md-6 col-sm-6 col-12">
                    <div className="schedule-group-item__date">
                        <DatePicker
                            error={isErrorsShown && errors.startAt}
                            includeDates={formData.endAt
                                ? getIncludedDates(formData.endAt)
                                : null}
                            inputClassName="input-date"
                            label="Start Date"
                            minDate={moment().toDate()}
                            onChange={(date) => handleStepChange("startAt", moment(date).format("YYYY-MM-DD"))}
                            placeholderText="Select Date"
                            selected={formData?.startAt
                                ? new Date(formData?.startAt)
                                : null}
                            wrapperClassName="w-full"
                        />
                    </div>
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <div className="schedule-group-item__date">
                        <DatePicker
                            error={isErrorsShown && errors.endAt}
                            includeDates={formData.startAt
                                ? getIncludedDates(formData.startAt)
                                : null}
                            inputClassName="input-date"
                            label="End Date"
                            minDate={moment().toDate()}
                            onChange={(date) => handleStepChange("endAt", moment(date).format("YYYY-MM-DD"))}
                            placeholderText="Select Date"
                            selected={formData?.endAt
                                ? moment(formData?.endAt).toDate()
                                : null}
                            wrapperClassName="w-full"
                        />
                    </div>
                </div>
            </div>

            <div className="page-action-group">
                <Link
                    className="btn btn--default btn--sm"
                    to="/"
                >
                    Cancel
                </Link>

                <button
                    className="btn btn--primary btn--sm"
                    onClick={handleSubmit}
                >
                    {
                        campId && chatId
                            ? (
                                <span className="info">
                                    Apply Changes
                                </span>
                            )


                            : (
                                <>
                                    <span className="info">
                                        Continue
                                    </span>

                                    <span className="ico">
                                        <svg
                                            fill="none"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            width="14"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.25 2.625L9.625 7L5.25 11.375"
                                                stroke="#02020A"
                                                strokeLinecap="square"
                                                strokeWidth="1.6"
                                            />
                                        </svg>
                                    </span>
                                </>
                            )


                    }
                </button>
            </div>
        </>
    );
};

export default TimeAndDate;
