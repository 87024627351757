import { useEffect } from "react";
import {
    Link,
    Outlet, useNavigate,
    useParams
} from "react-router-dom";
import { toast } from "react-toastify";
import {
    useDispatch, useSelector
} from "react-redux";
import { io } from "socket.io-client";
import Button from "../components/ui/buttons/Button";
import { getImageSrc } from "../helpers/utils";
import { getUser } from "../redux/slices/applicationSlice";
import {
    addUnreadMessage, changeStatus, setNewMessage, setUnreadMessages, upChatToTop, updateAcademyAccept, updateChat, updateChatPrivateLesson, updateChatPrivateLessonInvoice
} from "../redux/slices/chatsSlice";
import { setUnreadNotificationsCount } from "../redux/slices/notificationsSlice";
import {
    getChatSocket,
    setOnlineOneUser,
    setOnlineUsers,
    setSocket,
    setUserOffline
} from "../redux/slices/socketChatSlice";
import { getAllChatsFetch, getChatByIdFetch, updateCurrentChatFetch } from "../redux/thunks/chatsThunks";
import { readNotificationsFetch } from "../redux/thunks/notificationsThunks";
import { ReactComponent as ChevronNextIcon } from "../assets/img/ico-chevron--next.svg";
import { ScrollToTop } from "../components/parts/ScrollToTop";

const SocketProvider = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const { chatId } = useParams()
    const chatSocket = useSelector(getChatSocket);
    const { currentChatData } = useSelector(state => state.chats);

    useEffect(() => {
        const token = localStorage.getItem("authToken");

        const req = async () => {
            const socket = io("https://bjj.webstaginghub.com", {
                path: "/socket",
                transports: ["websocket"],
                auth: { token }
            });

            dispatch(getAllChatsFetch());
            dispatch(setSocket(socket));
            socket.on("chat:newMessage", (data) => {
                dispatch(setNewMessage(data));
            });
            socket.on("chat:newMessage", (data) => {
                dispatch(upChatToTop(data));
            });
            socket.on("chat:onlineChatUsers", (data) => {
                dispatch(setOnlineUsers(data));
            });
            socket.on("chat:userOffline", (data) => {
                dispatch(setUserOffline(data));
            });
            socket.on("chat:userOnline", (data) => {
                dispatch(setOnlineOneUser(data));
            });
            socket.on("chat:unreadMessagesCount", (data) => {
                dispatch(setUnreadMessages(data));
            });
            socket.on("chat:readedMessages", (data) => {
                dispatch(addUnreadMessage(data));
            });
            socket.on("chat:eventUpdate", (data) => {
                dispatch(changeStatus(data));
            });
            socket.on("chat:updated", (data) => {
                dispatch(updateChat(data));
            });
            socket.on("invoice:new", (data) => {
                dispatch(updateChatPrivateLessonInvoice(data));
            });
            socket.on("privateLesson:updated", (data) => {
                dispatch(updateChatPrivateLesson(data));
            });
            socket.on("chat-state:update", (data) => {
                dispatch(updateChat(data));
            });
            socket.on("chat:update:academy-accept", (data) => {
                dispatch(updateAcademyAccept(data));
            });
            socket.on("notifications:unreaded", (data) => {
                dispatch(setUnreadNotificationsCount(data));
            });
            socket.on("notifications:new", (data) => {
                dispatch(setUnreadNotificationsCount(data.length));
                const requestTypes = ['request_seminar', 'request_plancamp', 'request_private_lesson']

                const handleRead = (id, instructorId, toastId) => {
                    navigate(`/instructors/${instructorId}`);
                    dispatch(readNotificationsFetch({body: { ids: [id] }}));

                    if (toastId) {
                        toast.dismiss(toastId);
                    }
                };

                if (data.length) {
                    data.forEach((item) => {
                        const isRequest = requestTypes.includes(item?.type);
                        const toastId = toast((
                            <div className={"col-group gap--sm"}>
                                <div className={"row-group gap--sm"}>
                                    <div className={"image-wrapper avatar"}>
                                        <img
                                            src={getImageSrc(item?.payload?.photo || item?.payload?.user?.photo)}
                                            alt={"instructor"}
                                        />
                                    </div>
                                    <h6>{item?.payload?.fullName || item?.payload?.user?.fullName}</h6>
                                </div>
                                <p className={"color-text"}>
                                    {item.message}
                                </p>
                                {
                                    isRequest && item?.payload?.chatId ? (
                                        <Button
                                            className={"btn--primary btn--sm"}
                                            onClick={() => navigate(`/chat/${item?.payload?.chatId}`)}
                                        >
                                            <span className={"info"}>Open Chat</span>
                                            <span className={"ico ico--20"}>
                                                <ChevronNextIcon />
                                            </span>
                                        </Button>
                                    ) : isRequest ? (
                                        <Button
                                            className={"btn--primary btn--sm"}
                                            onClick={() => navigate(`/requests`)}
                                        >
                                            <span className={"info"}>Open Requests</span>
                                            <span className={"ico ico--20"}>
                                                <ChevronNextIcon />
                                            </span>
                                        </Button>
                                    )
                                        : (
                                            <Button
                                                className={"btn--primary btn--sm"}
                                                onClick={() => handleRead(item.id, item?.payload?.instructorId, toastId)}
                                            >
                                                <span className={"info"}>See Profile</span>
                                                <span className={"ico ico--20"}>
                                                    <ChevronNextIcon />
                                                </span>
                                            </Button>
                                        )
                                }
                            </div>
                        ), { hideProgressBar: true });
                    });
                }
            });
        };

        if (token && !chatSocket) {
            req();
        }
    }, [user?.id]);

    return (
        <>
            <Outlet />

            <ScrollToTop />
        </>
    );
};

export default SocketProvider;
