import profilePlaceholder from "../../../assets/img/user-profile.svg";
import Loader from "../../../components/ui/Loader";
import { getImageSrc } from "../../../helpers/utils";
import Message from "./Message";
import classNames from "classnames";
import {
    useEffect, useLayoutEffect, useMemo, useRef, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useParams
} from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { getUser } from "../../../redux/slices/applicationSlice";
import {
    clearCurrentChatData, clearUnreadMessages, getUnreadMessages
} from "../../../redux/slices/chatsSlice";
import {
    getChatOnlineUsers, getChatSocket
} from "../../../redux/slices/socketChatSlice";
import {
    getChatByIdFetch, sendChatMessageFetch
} from "../../../redux/thunks/chatsThunks";
import { ChatSidebarPrivateLesson } from "./ChatSidebarPrivateLesson";
import ChatSidebarSeminar from "./ChatSidebarSeminar";
import ChatSidebarCamp from "./ChatSidebarCamp";
import Button from "../../../components/ui/buttons/Button";
import { ReactComponent as AjustIcon } from "../../../assets/img/icon-ajust.svg";
import { ReactComponent as BackIcon } from "../../../assets/img/caret-right.svg";
import DropDown from "../../../components/ui/DropDown";
import { acceptSeminarRequestFetch } from "../../../redux/thunks/seminarsThunks";

export const CurrentChat = () => {
    const { chatId } = useParams();
    const socket = useSelector(getChatSocket);
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState("");
    const user = useSelector(getUser);
    const navigate = useNavigate();
    const {
        isLoading: isChatsLoading,
        isMessagesLoading,
        currentChatData,
        isCurrentChatDataLoading,
        messages: chatMessagesAll
    } = useSelector((state) => state.chats);
    const onlineArr = useSelector(getChatOnlineUsers);
    const unread = useSelector(getUnreadMessages);
    const [file, setFile] = useState(null);

    // const [preview, setPreview] = useState(null);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        setFile(selectedFile);
        // setPreview(URL.createObjectURL(selectedFile));
    };

    const handleFileRemove = () => {
        setFile(null);
        // setPreview(null);
    };

    const chatMessages = chatMessagesAll?.filter((message) => message.chatId + "" === chatId) || [];
    const currentChat = currentChatData || null;

    const getRecipient = (chat) => {
        return chat?.users?.find((recipient) => recipient?.id !== user?.id);
    };

    const isOnline = onlineArr.includes(getRecipient(currentChat)?.id);

    const handleKeyDown = async (event) => {
        if (event.key === "Enter") {
            if (!canSendMessages) return;

            if (file) {
                const body = new FormData();

                body.append("message", inputValue);
                body.append("attachments", file, file.name);
                dispatch(sendChatMessageFetch({
                    chatId,
                    body
                }));
                setInputValue("");
                setFile(null);

                return;
            } else if (inputValue) {
                socket.emit("chat:sendMessage", {
                    chatId: chatId,
                    message: inputValue
                });
                setInputValue("");
            }
        }
    };

    const elementRef = useRef(null);

    useLayoutEffect(() => {
        elementRef.current?.scrollTo({
            top: elementRef.current.scrollHeight,
            behavior: "smooth"
        });
    }, [chatMessages]);
    useEffect(() => {
        if (chatId && chatMessages && !isMessagesLoading) {
            const arr = unread.filter((el) => el.chatId == chatId);

            if (arr.length) {
                const mess = arr.map((el) => el.messageId);

                socket.emit("chat:readMessages", { messagesIds: mess });
                dispatch(clearUnreadMessages(mess));
            }
        }
    }, [chatMessages, chatId]);

    const getFormattedMessages = () => {
        const formattedMessages = [...chatMessages];

        if (chatMessages?.length >= 1) {
            formattedMessages.splice(1, 0, {
                isSystemMessage: true,
                chatId: currentChat?.id,
                message: "Seminar Negotiation Started",
                createdAt: "2024-08-26T13:34:00.000Z",
                updatedAt: "2024-08-26T13:34:00.000Z",
                attachments: [],
                senderId: chatMessages[0].userId
            });
        }

        return formattedMessages;
    };

    const formattedMessages = useMemo(getFormattedMessages, [chatMessages, chatId]);
    const lastMessage = formattedMessages?.[formattedMessages?.length - 1];
    const canSendMessages = lastMessage?.senderId !== user?.id;
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);

    if (isChatsLoading) {
        return <Loader />;
    }

    if (!currentChat) {
        return (
            <div className="no-chats-block">
                <p className="chat-item__name">
                    Choose the chat
                </p>
            </div>
        );
    }

    const chatData = currentChatData;
    const seminarDetails = chatData.seminar;
    const academyAccept = currentChatData?.academyAccept;
    const isActiveSeminar = academyAccept?.status === "active";
    const status = academyAccept
        ? isActiveSeminar
            ? "done"
            : chatData?.academyAccept?.step || "done"
        : seminarDetails?.status === "active"
            ? "done"
            : [seminarDetails?.instructors?.[0]?.invite_status_instructor, seminarDetails?.instructors?.[0]?.invite_status_academy].includes("rejected")
                ? "rejected"
                : "pending";

    const handleAccept = async () => {
        const res = await dispatch(acceptSeminarRequestFetch({
            seminarId: seminarDetails.id,
            requestId: academyAccept?.id
        })).unwrap();

        if (res) {
            dispatch(getChatByIdFetch({ chatId: chatData?.id }));
        }
    };

    const statusMap = {
        rejected: { label: "Request Rejected" },
        pending: { label: "Pending Request" },
        location_and_date: {
            label: "Discussing Location & Date",
            stepName: "Location & Date",
            actions: (
                <Link
                    className="btn btn--primary btn--sm text-14 w-full"
                    to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}`}
                >
                    <span className="ico">
                        <AjustIcon />
                    </span>

                    <span className="info">
                        Adjust Location & Date
                    </span>
                </Link>
            )
        },
        details: {
            label: "Discussing Details",
            stepName: "Seminar Details",
            actions: (
                <Link
                    className="btn btn--primary btn--sm text-14 w-full"
                    to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/seminar-info`}
                >
                    <span className="ico">
                        <AjustIcon />
                    </span>

                    <span className="info">
                        Adjust Seminar Details
                    </span>
                </Link>
            )
        },
        design: {
            label: "Discussing Design",
            stepName: "Design & Text",
            actions: (
                <Link
                    className="btn btn--primary btn--sm text-14 w-full"
                    to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/design-text`}
                >
                    <span className="ico">
                        <AjustIcon />
                    </span>

                    <span className="info">
                        Adjust Design & Text
                    </span>
                </Link>
            )
        },
        terms: {
            label: "Booking Specific Terms",
            actions:
    <>
        {" "}

        {
            isTermsAccepted
                ? (
                    <Link
                        className="btn btn--primary btn--sm text-14 w-full"
                        to={`/edit-seminar/${chatId}/${chatData?.seminar?.id}/${academyAccept?.id}/summary`}
                    >
                        <span className="ico">
                            <AjustIcon />
                        </span>

                        <span className="info">
                            Proceed to Summary
                        </span>
                    </Link>
                )


                : (
                    <div className="row-group gap--xs">
                        <Button
                            className="btn btn--default btn--sm text-14 w-full"
                            onClick={() => setIsTermsAccepted(true)}
                        >
                            <span className="info">
                                Skip
                            </span>
                        </Button>

                        <Button
                            className="btn btn--primary btn--sm text-14 w-full"
                            onClick={() => setIsTermsAccepted(true)}
                        >
                            <span className="info">
                                Accept
                            </span>
                        </Button>
                    </div>
                )


        }
    </>
        },
        done: {
            label: "Active Seminar",
            button: null
        }
    };
    const isAccepted = academyAccept?.accepts?.length && academyAccept?.accepts.find(({ userId }) => userId === user?.id);
    const ownerId = seminarDetails?.userId;

    return (
        <>
            {currentChat && user && !isCurrentChatDataLoading && (
                <div className="messages">
                    <div className="messages__header">
                        <div className="current-chat">

                            <BackIcon
                                className="messages__header-back"
                                onClick={() => {
                                    dispatch(clearCurrentChatData());
                                    navigate("/chat");
                                }}
                            />

                            <div className="current-chat__image">
                                <img
                                    alt={getRecipient(currentChat)?.fullName}
                                    className="ico ico--sm"
                                    src={getImageSrc(getRecipient(currentChat)?.photo, profilePlaceholder, 64)}
                                />
                            </div>

                            <div className="current-chat__details">
                                <p className="current-chat__name">
                                    {getRecipient(currentChat)?.fullName}
                                </p>

                                <p className="current-chat__status">
                                    {isOnline
                                        ? "Online"
                                        : "Offline"}
                                </p>
                            </div>
                        </div>

                        {/* <div className="row-group gap--xxl">
                            <button className="btn btn--sm btn--square">
                                <span className="ico">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10.1365" cy="10.1365" r="6.47345" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></circle>
                                        <path d="M18.3362 18.3362L14.7139 14.7139" stroke="#02020A" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </button>
                            <button className="btn btn--sm btn--square">
                                <span className="ico">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.9968 16.0439C10.7446 16.0439 10.5383 16.2503 10.541 16.5025C10.541 16.7547 10.7474 16.961 10.9996 16.961C11.2518 16.961 11.4581 16.7547 11.4581 16.5025C11.4581 16.2503 11.2518 16.0439 10.9968 16.0439" stroke="#02020A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M10.9968 10.541C10.7446 10.541 10.5383 10.7474 10.541 10.9995C10.541 11.2517 10.7474 11.4581 10.9996 11.4581C11.2518 11.4581 11.4581 11.2517 11.4581 10.9995C11.4581 10.7474 11.2518 10.541 10.9968 10.541" stroke="#02020A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M10.9968 5.03906C10.7446 5.03906 10.5383 5.2454 10.541 5.49759C10.541 5.74978 10.7474 5.95611 10.9996 5.95611C11.2518 5.95611 11.4581 5.74978 11.4581 5.49759C11.4581 5.2454 11.2518 5.03906 10.9968 5.03906" stroke="#02020A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                </span>
                            </button>
                        </div> */}
                    </div>

                    {
                        chatId && !isCurrentChatDataLoading && currentChatData?.seminar && (
                            <div className="chat-mobile-status">
                                <div className="chat-mobile-status-block">
                                    <p className="chat-item__name">
                                        {currentChatData?.seminar?.seminarName}
                                    </p>

                                    <div className="row-group gap--sm">
                                        <span className="seminar__status">
                                            Status:
                                        </span>

                                        <span
                                            className={classNames("seminar__dot", {
                                                "--done": status === "done",
                                                "--rejected": status === "rejected"
                                            })}
                                        />

                                        {statusMap[status] && (
                                            <span className="chat-item__name">
                                                {statusMap[status].label}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <DropDown
                                    buttonClasses="fix-for-header"
                                    closeYourSelf={true}
                                    dropDownClasses="lh-16"
                                    dropDownListClasses="dropdown-menu header__dropdown-menu"
                                    labelButton={

                                        <div className="btn btn--sm btn--square">
                                            <span className="ico">
                                                <svg
                                                    fill="none"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    width="22"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.9968 16.0439C10.7446 16.0439 10.5383 16.2503 10.541 16.5025C10.541 16.7547 10.7474 16.961 10.9996 16.961C11.2518 16.961 11.4581 16.7547 11.4581 16.5025C11.4581 16.2503 11.2518 16.0439 10.9968 16.0439"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    />

                                                    <path
                                                        d="M10.9968 10.541C10.7446 10.541 10.5383 10.7474 10.541 10.9995C10.541 11.2517 10.7474 11.4581 10.9996 11.4581C11.2518 11.4581 11.4581 11.2517 11.4581 10.9995C11.4581 10.7474 11.2518 10.541 10.9968 10.541"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    />

                                                    <path
                                                        d="M10.9968 5.03906C10.7446 5.03906 10.5383 5.2454 10.541 5.49759C10.541 5.74978 10.7474 5.95611 10.9996 5.95611C11.2518 5.95611 11.4581 5.74978 11.4581 5.49759C11.4581 5.2454 11.2518 5.03906 10.9968 5.03906"
                                                        stroke="#02020A"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="1.5"
                                                    />
                                                </svg>
                                            </span>
                                        </div>

                                    }
                                >
                                    {
                                        !isAccepted && (
                                            <div className="">

                                                {
                                                // not active && is owner
                                                    !isActiveSeminar && user?.id === ownerId &&
                                        statusMap?.[status]?.actions

                                                }

                                                {

                                                    // not confirmed && not active && current status is terms && terms is accepted && not owner
                                                    // !isAccepted && !isActiveSeminar && !(status === "terms" && !isTermsAccepted) && user?.id !== ownerId && 
                                                    !isActiveSeminar && status !== "terms" && user?.id !== ownerId && (
                                                        <Button
                                                            className="btn btn--primary btn--sm text-14 w-full"
                                                            onClick={handleAccept}
                                                        >
                                                            <span className="info">
                                                                {"Accept "}

                                                                {statusMap?.[status]?.stepName || ""}
                                                            </span>
                                                        </Button>
                                                    )}

                                                {
                                                // not confirmed && not active && current status is terms && terms is accepted && not owner
                                                    !isActiveSeminar && status === "terms" && isTermsAccepted && user?.id !== ownerId && (
                                                        <div className="row-group gap--xs">
                                                            <Button
                                                                className="btn btn--default btn--sm text-14 w-full"
                                                                onClick={() => setIsTermsAccepted(true)}
                                                            >
                                                                <span className="ico">
                                                                    <AjustIcon />
                                                                </span>

                                                                <span className="info">
                                                                    Adjust
                                                                </span>
                                                            </Button>

                                                            <Button
                                                                className="btn btn--primary btn--sm text-14 w-full"
                                                                onClick={handleAccept}
                                                            >
                                                                <span className="info">
                                                                    Confirm Offer
                                                                </span>
                                                            </Button>
                                                        </div>
                                                    )}

                                                {
                                                // not confirmed && not active && current status is terms && not accept terms && not owner
                                                    status === "terms" && !isActiveSeminar && !isTermsAccepted && user?.id !== ownerId &&
                                    statusMap?.["terms"]?.actions
                                                }
                                            </div>
                                        )}
                                </DropDown>

                            </div>
                        )}

                    <div className="container">
                        <div
                            className="messages__body-wrapper"
                            ref={elementRef}
                        >
                            <div className="messages__body">
                                {!isMessagesLoading && chatMessages?.length
                                    ? formattedMessages.map((item, index) => (
                                        <Message
                                            user={user}
                                            recipient={getRecipient(currentChat)}
                                            data={item}
                                            key={index}
                                        />
                                    ))
                                    : isMessagesLoading
                                        ? (
                                            <div className="m-auto">
                                                <Loader />
                                            </div>
                                        )
                                        : (
                                            <div className="m-auto">
                                                <p>
                                                    There is no messages yet
                                                </p>
                                            </div>
                                        )}
                            </div>
                        </div>

                        <div>
                            {file && (
                                <div className="chat-files-part" >
                                    <div className="chat-selected-file">
                                        <p>
                                            {file.name}
                                        </p>

                                        <button onClick={handleFileRemove}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div
                                className={classNames("messages__footer", { "block-disabled": currentChatData?.status === "archived" || !canSendMessages })}
                                data-tooltip-id={!canSendMessages
                                    ? `my-tooltip-${chatId}`
                                    : ""}
                            >

                                <label className="btn btn--square messages__button">
                                    <input
                                        className="hidden"
                                        disabled={!canSendMessages}
                                        onChange={handleFileChange}
                                        type="file"
                                    />

                                    <span className="ico">
                                        <svg
                                            fill="none"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            width="22"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.2913 8.54227L9.43413 14.3995C9.26219 14.5715 9.02897 14.6681 8.78578 14.6682H7.33134C6.82486 14.6682 6.41429 14.2576 6.41429 13.7511V12.2967C6.41437 12.0532 6.50984 11.8195 6.68023 11.6456L13.1271 5.05199C13.7924 4.37145 14.7023 3.98547 15.654 3.98013C16.6057 3.97479 17.52 4.35054 18.1929 5.02356L18.2589 5.08959C18.9461 5.77137 19.3314 6.70014 19.3287 7.66819C19.3259 8.63623 18.9353 9.56279 18.2442 10.2406C16.0818 12.3581 13.1197 15.3055 11.4232 16.9984C10.5635 17.8554 9.39903 18.3366 8.18511 18.3364H5.49724C3.97782 18.3364 2.74609 17.1046 2.74609 15.5852V12.8992C2.74609 11.6833 3.22903 10.5173 4.08865 9.65741L9.78903 3.95703"
                                                stroke="#02020A"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.2"
                                            />
                                        </svg>
                                    </span>
                                </label>

                                <div className="form-group messages__input">
                                    <input
                                        disabled={!canSendMessages}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        placeholder={chatMessages?.length
                                            ? "Type your message here..."
                                            : "Type a message here and start the seminar negotiation"}
                                        style={{ resize: "none" }}
                                        value={inputValue}
                                    />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            )}

            {
                chatId && !isCurrentChatDataLoading && currentChatData?.seminar && currentChatData?.eventType === "seminar"
                    ? (
                        <ChatSidebarSeminar
                            chatData={currentChatData}
                            academyAccept={currentChatData?.academyAccept}
                        />
                    )


                    : null
            }

            {
                chatId && !isCurrentChatDataLoading && currentChatData?.plancamp && currentChatData?.eventType === "plancamp"
                    ? (
                        <ChatSidebarCamp
                            chatData={currentChatData}
                            academyAccept={currentChatData?.academyAccept}
                        />
                    )


                    : null
            }

            {
                chatId && !isCurrentChatDataLoading && currentChatData?.privateLesson && currentChatData?.eventType === "private-lessons"
                    ? (
                        <ChatSidebarPrivateLesson
                            chatData={currentChatData}
                            academyAccept={currentChatData?.academyAccept}
                        />
                    )


                    : null
            }

            {
                !canSendMessages && (
                    <Tooltip
                        border="1px solid var(--border-color)"
                        id={`my-tooltip-${chatId}`}
                        style={{
                            background: "var(--white)",
                            color: "var(--defaultTextColor)",
                            padding: 10
                        }}
                    >
                        <p>
                            You will be able to send messages after the other participant has responded
                        </p>
                    </Tooltip>
                )}
        </>
    );
};
