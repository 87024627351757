import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import Button from "../../../components/ui/buttons/Button";
import Input from "../../../components/ui/formElements/Input";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import Textarea from "../../../components/ui/formElements/Textarea";
import { trainingStyle } from "../../../constants";
import { getUser } from "../../../redux/slices/applicationSlice";
import { createRequesToPrivateLessonFetch } from "../../../redux/thunks/privateLessonsThunks";
import { getActiveSeminarsByInstructorIdFetch, getAllSeminarsWithFiltersFetch } from "../../../redux/thunks/seminarsThunks";
import EventDatesSelect from "../parts/EventDatesSelect";
import { getActivePlancampsToInstructorPrivateLessonsFetch } from "../../../redux/thunks/planCampThuncks";
import { getEventType, setEventType } from "../../../redux/slices/planningSlice";
import classNames from "classnames";

const TimeAndDate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { instructorId } = useParams();
    const user = useSelector(getUser);
    const eventType = useSelector(getEventType);
    const [
        formData,
        handleChange,
        errors,
        setErrorConfig,
        instructorInfo
    ] = useOutletContext();
    // const { seminars } = useSelector((state) => state.seminars);
    // const { camps } = useSelector((state) => state.planCamps);

    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        setIsErrorsShown(true);

        try {
            setIsLoading(true);
            console.log(errors);

            if (!errors.hasRequiredError) {
                // const eventType = "seminar"
                const newData = {
                    ...formData,
                    eventType
                };

                if (!formData.eventId) {
                    delete newData.eventId
                    delete newData.eventType
                }

                const res = await dispatch(createRequesToPrivateLessonFetch({
                    instructorId,
                    body: newData
                })).unwrap();
                if (res.success) {
                    navigate(`/instructors/${instructorId}`);
                }
                // const chatId = res?.data?.chat?.id || res?.chat?.id

                // if (chatId) {
                //     navigate(`/chat/${chatId}`);
                // }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const instructorTraningStyle = instructorInfo?.privateSession?.traningStyle;
    const availableDates = instructorInfo?.privateSession?.availability || [];

    const traningStyleOptions = trainingStyle.map(({ key, title }) => ({
        value: key,
        label: title
    })).filter((item) => {
        if (instructorTraningStyle === "gi_and_no-gi") {
            return true;
        } else {
            return item.value === instructorTraningStyle;
        }
    });

    // const seminarsOptions = seminars
    //     .map(item => ({
    //         label: item.seminarName,
    //         value: item.id
    //     }))
    // const campsOptions = camps
    //     .map(item => ({
    //         label: item.seminarName,
    //         value: item.id
    //     }))

    // useEffect(() => {
    //     dispatch(getActiveSeminarsByInstructorIdFetch({ instructorId }))
    //     dispatch(getActivePlancampsToInstructorPrivateLessonsFetch({ instructorId }))
    // }, [])

    useEffect(() => {
        setErrorConfig({
            exceptions: [],
            requiredFields: ["dates"],
            validators: {
                availableDates: (value) => {
                    if (!value.length) return false;

                    let isValid = true;

                    value.forEach((item) => {
                        if (!item.date) {
                            isValid = false;
                        }
                    });

                    return isValid;
                }
            }
        });
    }, [formData]);

    

    return (
        <>
            <div className="mb-42">
                <div className="row gx-16 py-24 mb-10 ">
                    <div className="col-12">
                        <h3 className="row-group">
                            Ticket and receipt are sent to the info below
                        </h3>
                    </div>

                    <div className="col-lg-4">
                        <div className="form-group input--lg">
                            <label className="label">
                                Account ID
                            </label>

                            <p className="fw-500 text-14">
                                {user?.id}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="form-group input--lg">
                            <label className="label">
                                Name
                            </label>

                            <p className="fw-500 text-14">
                                {user?.profile?.fullName}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="form-group input--lg">
                            <label className="label">
                                Email
                            </label>

                            <p className="fw-500 text-14">
                                {user?.email}
                            </p>
                        </div>
                    </div>

                    <div className="col-12">
                        <hr className="hr" />
                    </div>

                    <div className="col-12">
                        <h4 className="row-group">
                            Set Time & Date
                        </h4>
                    </div>

                    <div className="col-12">
                        <EventDatesSelect
                            isMultySlot
                            maxDays={14}
                            timeIntervals={30}
                            availableDates={availableDates}
                            dates={formData.dates}
                            handleChange={(dates) => handleChange("dates", dates)}
                            isErrorsShown={isErrorsShown}
                        />
                    </div>

                    <div className="col-12">
                        <hr className="hr" />
                    </div>

                    {/* <div className="col-12">
                        <div className="flex items-center gap-16 mb-3">
                            <Button
                                onClick = {() => dispatch(setEventType('seminar'))}
                                className={classNames('btn btn--sm', {
                                    'btn--primary': eventType === 'seminar',
                                    'btn-border': eventType !== 'seminar'
                                })}
                            >
                                Seminar
                            </Button>
                            <span>or</span>
                            <Button
                                onClick = {() => dispatch(setEventType('plancamp'))}
                                className={classNames('btn btn--sm', {
                                    'btn--primary': eventType === 'plancamp',
                                    'btn-border': eventType !== 'plancamp'
                                })}
                            >
                                Plancamp
                            </Button>
                        </div>
                        {eventType === 'seminar' && (
                            <SelectOne
                                error={isErrorsShown && errors?.eventId}
                                // label="Seminar"
                                onChange={(option) => handleChange("eventId", option?.value || null)}
                                isClearable = {true}
                                options={seminarsOptions}
                                placeholder="Select seminar"
                                value={formData.eventId}
                                wrapperClasses="select--outline select--outline-bg input--lg w-full"
                            />
                        )}
                        {eventType === 'plancamp' && (
                            <SelectOne
                                error={isErrorsShown && errors?.eventId}
                                // label="Seminar"
                                onChange={(option) => handleChange("eventId", option?.value || null)}
                                isClearable = {true}
                                options={campsOptions}
                                placeholder="Select plancamp"
                                value={formData.eventId}
                                wrapperClasses="select--outline select--outline-bg input--lg w-full"
                            />
                        )}

                    </div> */}

                    <div className="col-md-6 col-sm-6 col-12">
                        <Input
                            error={isErrorsShown && errors?.peopleAttending}
                            inputClasses="input--solid"
                            label="People Attending"
                            onChange={(e) => handleChange("peopleAttending", e.target.value)}
                            placeholder="0"
                            type="text"
                            value={formData.peopleAttending}
                            wrapperClasses="input--lg"
                        />
                    </div>

                    <div className="col-md-6 col-sm-6 col-12">
                        <SelectOne
                            error={isErrorsShown && errors?.style}
                            label="Gi or Nogi"
                            onChange={(option) => handleChange("style", option.value)}
                            options={traningStyleOptions}
                            placeholder="Gi or Nogi"
                            value={formData.style}
                            wrapperClasses="select--outline select--outline-bg input--lg w-full"
                        />

                    </div>

                    <div className="col-12">
                        <Textarea
                            error={isErrorsShown && errors?.message}
                            label="Optional Message"
                            onChange={(e) => handleChange("message", e.target.value)}
                            placeholder="Please write message here..."
                            style={{ minHeight: 140 }}
                            textareaClasses="input--solid"
                            value={formData.message}
                            wrapperClasses="input--lg"
                        />
                    </div>
                </div>
            </div>

            <hr className="hr mb-42 mt-42" />

            <div className="page-action-group">
                <Link
                    className="btn btn--default btn--sm"
                    to={-1}
                >
                    Cancel
                </Link>

                <Button
                    className="btn btn--primary btn--sm"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                >
                    <span className="info">
                        Continue
                    </span>

                    <span className="ico">
                        <svg
                            fill="none"
                            height="14"
                            viewBox="0 0 14 14"
                            width="14"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.25 2.625L9.625 7L5.25 11.375"
                                stroke="#02020A"
                                strokeLinecap="square"
                                strokeWidth="1.6"
                            />
                        </svg>
                    </span>
                </Button>
            </div>
        </>
    );
};

export default TimeAndDate;
