import { pick } from "lodash";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Link, useNavigate, useOutletContext, useParams
} from "react-router-dom";
import InviteInstructorModal from "../../../components/modals/InviteInstructorModal";
import CheckboxSwitch from "../../../components/ui/formElements/CheckboxSwitch";
import Input from "../../../components/ui/formElements/Input";
import SelectOne from "../../../components/ui/formElements/SelectOne";
import {
    belts, trainingStyle
} from "../../../constants";
import {
    checkErrors, convertObjectToFormData, getImageSrc
} from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import { updateCampFetch } from "../../../redux/thunks/planCampThuncks";
import { explanations } from "../../../constants/explanations";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import { USER_ROLE } from "../../../constants/enums";

const SeminarInformation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { chatId: paramsChatId, campId } = useParams();
    const chatId = paramsChatId === "null" ? null : paramsChatId;
    const user = useSelector(getUser);
    const [
        formData,
        handleChange,
        errors,
        handlePreviewChange,
        preview,
        setErrorConfig
    ] = useOutletContext();
    const [isErrorsShown, setIsErrorsShown] = useState(false);
    const [inviteInstructorModalOpen, setInviteInstructorModalOpen] = useState(false);
    const [instructors, setInstructors] = useState(preview?.instructors);
    const [editedFields, setEditedFields] = useState([]);

    const handleSubmit = async () => {
        setIsErrorsShown(true);
        console.log(errors);

        if (!errors?.hasRequiredError) {
            if (campId && chatId) {
                const body = new FormData();
                const dataToUpdate = pick(formData, editedFields);

                convertObjectToFormData(dataToUpdate, body);
                const res = await dispatch(updateCampFetch({
                    campId,
                    body
                })).unwrap();

                if (res) {
                    navigate(`/chat/${chatId}`);
                }
            } else {
                navigate("../design-text");
            }
        }
    };

    const handleStepChange = (name, value) => {
        handleChange(name, value);
        setEditedFields((prev) => [...prev, name]);
    };

    const specificBeltOptions = belts.filter(({ key }) => !["blue_belt", "white_belt"].includes(key)).map(({ key, title }) => ({
        value: key,
        label: title
    }));
    const traningStyleOptions = trainingStyle.map(({ key, title }) => ({
        value: key,
        label: title
    }));

    const handleAddInstructors = (data) => {
        setInstructors(data);
        handleStepChange("invited_instructors", data.map((item) => item?.user?.id));
        setInviteInstructorModalOpen(false);
        handlePreviewChange("instructors", data);
    };

    const handleRemoveInstructor = (index) => {
        const updatedInstructors = instructors.filter((_, i) => i !== index);

        handleStepChange("invited_instructors", updatedInstructors.map((item) => item?.user?.id));
        setInstructors(updatedInstructors);
        handlePreviewChange("instructors", updatedInstructors);
    };

    useEffect(() => {
        setErrorConfig({
            editedFields: [
                ...(campId && chatId
                    ? ["priceFrom", "priceTo"]
                    : []),
            ],
            requiredFields: [
                "traningStyle",
                "maxGymCapacity",
                "specificBelt",
                ...(!(campId && chatId)
                    ? ["priceFrom", "priceTo"]
                    : []),
            ]
        });
    }, [formData, user]);

    return (
        <>
            <h6 className="heading mb-32">
                My Terms
            </h6>

            <div className="row gx-16 py-24 mb-42">
                {
                    !(campId && chatId) && (
                        <div className="col-12">
                            <div className="form-group input--lg mb-16">
                                <label className="label">
                                    Price range for Camp
                                </label>

                                <div className="row-group w-full gap-10">
                                    <div className="form-group flex-auto input--lg">
                                        <Input
                                            error={isErrorsShown && errors?.priceFrom}
                                            inputClasses="input--solid"
                                            onChange={(e) => handleStepChange("priceFrom", e.target.value)}
                                            placeholder="3000"
                                            type="number"
                                            min={0}
                                            max={99999900}
                                            value={formData.priceFrom}
                                            wrapperClasses="input--currency"
                                        />
                                    </div>

                                    <span className="input-delimiter" />

                                    <div className="form-group flex-auto input--lg">
                                        <Input
                                            error={isErrorsShown && errors?.priceTo}
                                            inputClasses="input--solid"
                                            onChange={(e) => handleStepChange("priceTo", e.target.value)}
                                            placeholder="6000"
                                            type="number"
                                            min={0}
                                            max={99999900}
                                            value={formData.priceTo}
                                            wrapperClasses="input--currency"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    campId && chatId && (
                        <div className="col-12">
                            <div className="row gx-16 mb-32">

                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="form-group flex-auto input--lg">
                                        <label className="label">
                                            Ticket Price
                                        </label>

                                        <Input
                                            error={isErrorsShown && errors?.minimalPriceTicket}
                                            inputClasses="input--solid"
                                            onChange={(e) => handleStepChange("minimalPriceTicket", +e.target.value)}
                                            placeholder="100.00"
                                            type="text"
                                            value={formData.minimalPriceTicket}
                                            wrapperClasses="form-group flex-auto input--lg input--currency"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <Input
                                        error={isErrorsShown && errors?.instructorPrice}
                                        wrapperClasses="form-group flex-auto input--lg input--currency"
                                        inputClasses="input--solid"
                                        label="Instructor Price"
                                        onChange={(e) => handleStepChange("instructorPrice", e.target.value)}
                                        placeholder="100"
                                        value={formData.instructorPrice}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }


                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        error={isErrorsShown && errors?.traningStyle}
                        label="Gi or Nogi"
                        onChange={(option) => handleStepChange("traningStyle", option.value)}
                        options={traningStyleOptions}
                        placeholder="Gi or Nogi"
                        value={formData.traningStyle}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <Input
                        error={isErrorsShown && errors?.maxGymCapacity}
                        inputClasses="input--solid"
                        label="Max Gym capacity"
                        onChange={(e) => handleStepChange("maxGymCapacity", e.target.value)}
                        placeholder="0"
                        type="text"
                        value={formData.maxGymCapacity}
                        wrapperClasses="input--lg"
                    />
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <SelectOne
                        error={isErrorsShown && errors?.specificBelt}
                        label="Specific Belt for instructor"
                        onChange={(option) => handleStepChange("specificBelt", option.value)}
                        options={specificBeltOptions}
                        placeholder="Specific Belt for instructor"
                        value={formData.specificBelt}
                        wrapperClasses="select--outline select--outline-bg input--lg w-full"
                    />
                </div>

                <CheckboxSwitch
                    checked={user?.role === USER_ROLE.instructor
                        ? formData?.instructor?.allowUnder18 === "1"
                        : formData?.allowUnder18 === "1"}
                    onChange={() => handleStepChange(user?.role === USER_ROLE.instructor
                        ? "instructor.allowUnder18"
                        : "allowUnder18", (user?.role === USER_ROLE.instructor
                            ? formData?.instructor?.allowUnder18
                            : formData?.allowUnder18) === "0"
                        ? "1"
                        : "0")}
                    text="Do you allow students under the age of 18 to attend your seminar/camp?"
                />
            </div>

            {
                user?.role === USER_ROLE.instructor
                    ? (
                        <>
                            <hr className="hr-horisontal mb-42" />

                            <div className="mb-42">
                                <h6 className="heading mb-32">
                                    Invite Additional Instructor {" "}

                                    <span className="color-grey">
                                        (optional)
                                    </span>
                                </h6>
                                <TooltipInfo content={explanations.inviteInstructor} />
                                {
                                    !!instructors?.length
                                        ? (
                                            <ul className="invite-instructors-list mb-32">
                                                {
                                                    instructors.map((instructor, index) => (
                                                        <li
                                                            key={index}
                                                            className="invite-instructor__item"
                                                        >
                                                            <div className="invite-instructor__img">
                                                                <img
                                                                    src={getImageSrc(instructor.photo)}
                                                                    alt={instructor.fullName}
                                                                />
                                                            </div>

                                                            <div className="invite-instructor__group-info">
                                                                <span className="invite-instructor__name">
                                                                    {instructor.fullName}
                                                                </span>

                                                                <span className="invite-instructor__email color-grey fs-14">
                                                                    {instructor?.user?.email}
                                                                </span>
                                                            </div>

                                                            <button
                                                                onClick={() => handleRemoveInstructor(index)}
                                                                className="btn"
                                                            >
                                                                <svg
                                                                    width="24"
                                                                    height="25"
                                                                    viewBox="0 0 24 25"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g>
                                                                        <path
                                                                            d="M18.75 5.75L5.25 19.25"
                                                                            stroke="#BDBDBD"
                                                                            strokeWidth="1.2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                        </path>

                                                                        <path
                                                                            d="M18.75 19.25L5.25 5.75"
                                                                            stroke="#BDBDBD"
                                                                            strokeWidth="1.2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                        </path>
                                                                    </g>

                                                                    <defs>
                                                                        <clipPath>
                                                                            <rect
                                                                                width="24"
                                                                                height="24"
                                                                                fill="white"
                                                                                transform="translate(0 0.5)"
                                                                            >
                                                                            </rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    )

                                                    )
                                                }
                                            </ul>
                                        )


                                        : null
                                }

                                <button
                                    className="btn btn--sm btn--primary"
                                    data-modal="#invite-instructors"
                                    onClick={() => setInviteInstructorModalOpen(true)}
                                >
                                    <span className="info">
                                        Send Invite
                                    </span>

                                    <span className="ico">
                                        <svg
                                            fill="none"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            width="14"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_910_51633)">
                                                <path
                                                    d="M5.25 2.625L9.625 7L5.25 11.375"
                                                    stroke="#02020A"
                                                    strokeLinecap="square"
                                                    strokeWidth="1.6"
                                                />
                                            </g>

                                            <defs>
                                                <clipPath id="clip0_910_51633">
                                                    <rect
                                                        fill="white"
                                                        height="14"
                                                        width="14"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </>
                    )


                    : null
            }

            <div className="page-action-group">
                <Link
                    className="btn btn--default btn--sm"
                    to=".."
                >
                    Cancel
                </Link>

                <button
                    className="btn btn--primary btn--sm"
                    onClick={handleSubmit}
                >
                    {
                        campId && chatId
                            ? (
                                <span className="info">
                                    Apply Changes
                                </span>
                            )


                            : (
                                <>
                                    <span className="info">
                                        Continue
                                    </span>

                                    <span className="ico">
                                        <svg
                                            fill="none"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            width="14"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.25 2.625L9.625 7L5.25 11.375"
                                                stroke="#02020A"
                                                strokeLinecap="square"
                                                strokeWidth="1.6"
                                            />
                                        </svg>
                                    </span>
                                </>
                            )


                    }
                </button>
            </div>

            {
                inviteInstructorModalOpen
                    ? (
                        <InviteInstructorModal
                            open={inviteInstructorModalOpen}
                            handleClose={() => setInviteInstructorModalOpen(false)}
                            onSubmit={handleAddInstructors}
                            data={instructors}
                        />
                    )


                    : null
            }
        </>
    );
};

export default SeminarInformation;
