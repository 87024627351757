import React, {
    useEffect, useState
} from "react";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import moment from "moment";
import {
    eachDayOfInterval, format
} from "date-fns";
import { ReactComponent as PlusIcon } from "../../../assets/img/ico-plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/ico-trash.svg";
import { date } from "yup";
import classNames from "classnames";

const nextDay = (date) => {
    if (date) {
        return moment(date).day(moment(date).day() + 1)
            .toDate();
    } else {
        return null;
    }
};

const SpecificDatesSelect = ({ handleChange, disabled, errors, isErrorsShown }) => {
    const [dates, setDates] = useState([
        {
            date: null,
            startTime: null,
            endTime: null
        }
    ]);

    useEffect(() => {
        handleChange(dates);
    }, [dates]);

    const handleAddTimeSlot = () => {
        setDates([
            ...dates, {
                date: nextDay(dates[0]?.date),
                startTime: null,
                endTime: null
            }
        ]);
    };

    const handleChangeDate = (date, index) => {
        setDates(dates.map((oldDate, oldIndex) => oldIndex === index
            ? {
                date: format(date, 'yyyy-MM-dd'),
                startTime: null,
                endTime: null,
            }
            : oldDate));

        if (index === 0 && dates.length > 1) {
            setDates((prev) => {
                prev[1] = {
                    date: nextDay(date),
                    startTime: null,
                    endTime: null
                };

                return prev;
            });
        }
    };

    const handleRemoveTimeSlot = (index) => {
        setDates(dates.filter((_, oldIndex) => index !== oldIndex));
    };

    return (
        <div className={classNames("specific-dates-wrapper", { "block-disabled": disabled })}>
            {dates?.map((date, index) => (
                <React.Fragment key={index}>
                    <div className="schedule-group-item__date">
                        <DatePicker
                            error={!disabled && isErrorsShown && errors?.availableDates && !date.date}
                            excludeDates={dates.filter((item) => item.date).map((item) => item.date)}
                            label={`Day #${index + 1}`}
                            minDate={moment().toDate()}
                            onChange={(date) => handleChangeDate(date, index)}
                            placeholderText="Select Event Date"
                            selected={date.date}
                        />
                    </div>

                    {dates.length > 1 && (
                        <button
                            onClick={() => handleRemoveTimeSlot(index)}
                            style={{ marginTop: 26 }}
                            className="btn btn--outline-default btn--square btn--md rounded-full"
                        >
                            <DeleteIcon />
                        </button>
                    )}
                </React.Fragment>
            )



            )}

            {dates.length < 2 && (
                <button
                    className="btn btn--outline-default btn--square btn--md rounded-full"
                    onClick={handleAddTimeSlot}
                    style={{ marginTop: 26 }}
                >
                    <PlusIcon />
                </button>
            )}
        </div>
    );
};

export default SpecificDatesSelect;
