import Button from "../../../components/ui/buttons/Button";
import {
    getDateRange, getTeachStyle, getTrainingStyle
} from "../../../helpers/utils";

import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as DotsIcon } from "../../../assets/img/icon-dots-horisontal.svg";
import CancelEventModal from "../../../components/modals/CancelEventModal";
import DropDown from "../../../components/ui/DropDown";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { toast } from "react-toastify";
import ConfirmComplateModal from "../../../components/modals/ConfirmComplateModal";
import api from "../../../api";
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/slices/applicationSlice";
import SemianrActionsDropdown from "./SemianrActionsDropdown";

const SeminarOverviewHeader = ({ pageData, eventType }) => {
    const [cancelEventModalOpen, setCancelEventModalOpen] = useState(false)
    const [modalConfirmOpen, setModalConfirmOpen] = useState(null)
    const [confirmComplateModal, setConfirmComplateModal] = useState(false)
    const user = useSelector(getUser)
    const trainingStyle = getTrainingStyle(pageData?.traningStyle);
    const isActive = pageData?.status === "active"

    const chatId = pageData?._eventRequest?.chatId;
    // const editLink = chatId && !isActive
    //     ? `/chat/${chatId}` : `/edit-${eventType === "plancamp"
    //         ? "camp" : "seminar"}/null/${pageData.id}/null${isActive ? "/design-text" : ""}`
    const editLink = `/edit-${eventType === "plancamp"
        ? "camp" : "seminar"}/null/${pageData.id}/null${isActive ? "/design-text" : ""}`


    if (!pageData) {
        return null
    }


    const handleBuyRemainingTickets = () => {
        const onSubmit = async () => {
            try {
                const res = await api.tickets.buyRemainingTickets({
                    eventId: pageData.id,
                    eventType: pageData._type
                });
                if (res.data?.session?.url) {
                    // toast.success("Remaining tickets have been purchased successfully!");
                    window.open(res.data?.session?.url)
                    setModalConfirmOpen(null)
                } else {
                    toast.error(res?.message || "Failed to purchase remaining tickets.");
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while purchasing tickets.");
            }
        };
        const count = pageData?.minimumAttendance - pageData?.ticketsPurchased;
        const price = pageData?.minimalPriceTicket;

        const totalPrice = (price * count) + (price * count * pageData._ticketFee);

        setModalConfirmOpen({
            title: "Purchase Remaining Tickets",
            description: `Are you sure you want to purchase the remaining <b>${count}</b> unsold tickets for <b>$${totalPrice}</b>  to meet the minimum attendance?`,
            onSubmit
        });
    };

    function calculateInstructorPrice(minimumAttendance, minimalPriceTicket) {
        const instructorPrice = minimumAttendance * minimalPriceTicket / 1.05;
        if (isNaN(instructorPrice) || instructorPrice < 0) return 0;
        return Math.floor(instructorPrice);
    }
    const handleReduceInstructorFee = () => {
        const val = calculateInstructorPrice(pageData.ticketsPurchased, pageData.minimalPriceTicket)
        const onSubmit = async () => {
            try {
                const res = await api.payoutMethods.reduceInstructorPrice({
                    eventId: pageData.id,
                    eventType: pageData._type
                });
                if (res?.success) {
                    toast.success("Reduced fee has been agreed upon successfully!");
                    setModalConfirmOpen(null)
                } else {
                    toast.error(res?.message || "Failed to confirm reduced fee.");
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while confirming reduced fee.");
            }
        };

        setModalConfirmOpen({
            title: "Confirm Reduced Fee",
            description: `Are you agree that the instructor's fee will be reduced to <b>$${val}</b>?`,
            onSubmit
        });
    };

    const handleProceedWithSeminar = () => {
        const onSubmit = async () => {
            try {
                const res = await api.events.cancelOrActivateEventRequest({
                    eventId: pageData.id,
                    eventType: pageData._type,
                    mode: "shared_profit_active"
                })
                if (res?.success) {
                    toast.success("Seminar will proceed as scheduled, despite minimum attendance not being met.");
                    setModalConfirmOpen(null)
                } else {
                    toast.error(res?.message || "Failed to confirm proceeding with the seminar.");
                }
            } catch (error) {
                console.error(error);
                toast.error("An error occurred while confirming seminar continuation.");
            }
        };

        setModalConfirmOpen({
            title: "Confirm Proceeding with Seminar",
            description: "Do you want to proceed with the seminar, even though the minimum attendance has not been met?",
            onSubmit
        });
    };

    const isDeadline = pageData.eventStartStatus === "minimum_attendance_not_reached";
    const isWaitProfit = pageData.eventStartStatus === "waiting_confirm_by_profit"

    const releases = pageData?.releases || []
    const isReleased = pageData?.status === "finished" && releases.find(item => item?.userId === user?.id);

    return (
        <>
            <div className="sidebar-headers">
                <div className="sidebar-header heading-row">
                    <div className="row-group gap--md">
                        <h3 className="heading">
                            {pageData?.seminarName}
                        </h3>
                        {
                            (isDeadline || isWaitProfit) && (<span className="color-white px-3 py-2 ttu italic bg-danger radius-10 fw-500">Minimum attendance is not met</span>)
                        }
                        {
                            isReleased && (<span className="color-white px-3 py-2 ttu italic bg-secondary radius-10 fw-500">Accepted</span>)
                        }
                    </div>

                    <div className="row-group gap--md">
                        {/* {
                            chatId && (
                                <Link
                                    to={`/chat/${chatId}`}
                                    className="btn  btn--md radius"
                                >
                                    <span>
                                        Open chat
                                    </span>
                                </Link>
                            )
                        } */}
                        <Link
                            to={editLink}
                            className="btn  btn--md radius"
                        >
                            <span>
                                {/* {chatId && !isActive ? "Open chat" : "Edit"} */}
                                Edit
                            </span>
                        </Link>


                        {/* {
                            (["active", "finished"].includes(pageData.status) || isDeadline || isWaitProfit) && (
                                <DropDown
                                    dropDownClasses="ml-auto card-actions"
                                    dropDownListClasses="dropdown-menu"
                                    labelButton={(
                                        <DotsIcon />
                                    )}
                                    buttonClasses={"btn btn--square btn--md rounded-full btn-border"}
                                >
                                    <div className="dropdown-menu__body--item">
                                        {
                                            pageData.status === "active" && (
                                                <>
                                                    {(isDeadline || isWaitProfit) && !!pageData.ticketsPurchased && (
                                                        <>
                                                            {
                                                                !isWaitProfit ? (
                                                                    <>
                                                                        {
                                                                            user?.role === "academy" && (
                                                                                <Button
                                                                                    className="btn btn--sm btn--thin active"
                                                                                    onClick={handleBuyRemainingTickets}
                                                                                >
                                                                                    Buys Remaining Tickets
                                                                                </Button>
                                                                            )
                                                                        }
                                                                        {
                                                                            user?.role === "instructor" && (
                                                                                <Button
                                                                                    className="btn btn--sm btn--thin active"
                                                                                    onClick={handleReduceInstructorFee}
                                                                                >
                                                                                    Reduce instructor fee
                                                                                </Button>
                                                                            )
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <Button
                                                                        className="btn btn--sm btn--thin active"
                                                                        onClick={handleProceedWithSeminar}
                                                                    >
                                                                        Proceed with a seminar
                                                                    </Button>
                                                                )}
                                                        </>
                                                    )}
                                                    <Button
                                                        className="btn btn--sm btn--thin active"
                                                        onClick={() => setCancelEventModalOpen(true)}
                                                    >
                                                        Cancel Event
                                                    </Button>
                                                </>
                                            )
                                        }
                                        {
                                            pageData.status === "finished" && (
                                                <Button
                                                    className="btn btn--sm btn--thin active"
                                                    onClick={() => setConfirmComplateModal(pageData)}
                                                >
                                                    Completion Confirmation
                                                </Button>
                                            )
                                        }
                                    </div>
                                </DropDown>
                            )
                        } */}
                        <SemianrActionsDropdown event={pageData} />

                    </div>
                </div>

                <div className="sidebar-header heading-row">
                    <div className="options">
                        {pageData?.status === "pending"
                            ? (
                                <>
                                    {(pageData?.seminarMinPrice) && (
                                        <div className="option">
                                            <div className="price-group">
                                                <span className="color-grey mr-8 text-sm">
                                                    from

                                                    {" "}
                                                </span>

                                                <span className="price-group__price">
                                                    ${pageData?.seminarMinPrice}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    <div className="option">
                                        <p className="text-sm font-500">
                                            Planned Seminar
                                        </p>
                                    </div>
                                </>
                            )


                            : (
                                <div className="option">
                                    <div className="price-group">
                                        <span className="price-group__price">
                                            ${pageData.minimalPriceTicket}

                                            {" "}
                                        </span>

                                        <span className="color-grey mr-8 text-sm">
                                            / ticket
                                        </span>

                                        <span className="price-group__amount">
                                            {pageData?.maxGymCapacity - pageData?.ticketsPurchased} left
                                        </span>
                                    </div>
                                </div>
                            )}

                        <div className="option">
                            <span className="card-seminar__gi">
                                <span className="ico">
                                    {trainingStyle[0]?.icon}
                                </span>

                                <span>
                                    {trainingStyle[0]?.title}
                                </span>
                            </span>

                            <ul className="list-point-row">
                                {pageData?.seminarDates && (
                                    <li>
                                        <p className="text-sm">
                                            {getDateRange(pageData?.seminarDates)}
                                        </p>
                                    </li>
                                )}

                                {pageData?.teachingStyles && (
                                    <li>
                                        <p className="text-sm">
                                            {getTeachStyle(pageData?.teachingStyles).join(", ")}
                                        </p>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                {cancelEventModalOpen && (
                    <CancelEventModal
                        open={cancelEventModalOpen}
                        handleClose={() => setCancelEventModalOpen(false)}
                        data={pageData}
                    />
                )}
            </div>
            {
                modalConfirmOpen && (
                    <ConfirmModal
                        open={modalConfirmOpen}
                        onClose={() => setModalConfirmOpen(null)}
                        {...modalConfirmOpen}
                    />
                )
            }
            {
                confirmComplateModal && (
                    <ConfirmComplateModal
                        open={confirmComplateModal}
                        data={confirmComplateModal}
                        handleClose={() => setConfirmComplateModal(null)}
                    />
                )
            }
        </>
    );
};

export default SeminarOverviewHeader;
