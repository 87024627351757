import React, {
    useEffect, useRef, useState
} from "react";
import Button from "./buttons/Button";
import useClickOutside from "../../hooks/useClickOutside";
import { createPortal } from "react-dom";
import classNames from "classnames";

const DropDown = ({
    children, disabled, dropDownClasses = "", dropDownListClasses = "", labelButton, buttonClasses, position = "left", closeYourSelf = false,
    overflow = "auto",
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropSytle, setDropStyle] = useState({});
    const dropRef = useRef(null);
    const dropdownNode = useClickOutside(() => {
        setIsOpen(false);
    }, dropRef);

    const handler = () => {
        setIsOpen(!isOpen);
    };

    const getStyles = function () {
        const rect = dropdownNode.current.getBoundingClientRect();
        const dropListWidth = parseFloat(getComputedStyle(dropRef.current).getPropertyValue("width"));
        const dropListHeight = parseFloat(getComputedStyle(dropRef.current).getPropertyValue("height"));
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const spaceOnRight = viewportWidth - rect.right;
        const spaceOnLeft = rect.left;
        const spaceBelow = viewportHeight - rect.bottom;
        const spaceAbove = rect.top;
        let leftPosition;
        let topPosition;

        if (spaceOnRight >= dropListWidth) {
            leftPosition = rect.right - dropListWidth;
        } else if (spaceOnLeft >= dropListWidth) {
            leftPosition = rect.left - dropListWidth;
        } else {
            leftPosition = rect.left;
        }

        if (spaceBelow >= dropListHeight) {
            topPosition = rect.bottom;
        } else if (spaceAbove >= dropListHeight) {
            topPosition = rect.top - dropListHeight;
        } else {
            topPosition = rect.bottom;
        }

        setDropStyle({
            top: topPosition,
            left: leftPosition
        });
    };

    useEffect(() => {
        if (!isOpen) {
            setDropStyle({});

            return;
        }

        getStyles();
        window.addEventListener("resize", getStyles);
        window.addEventListener("scroll", handler);

        return () => {
            window.removeEventListener("scroll", handler);
            window.removeEventListener("resize", getStyles);
        };
    }, [isOpen]);

    return (
        <div
            className={classNames("dropdown", dropDownClasses, { "dropdown-open": isOpen })}
            ref={dropdownNode}
        >
            <Button
                className={buttonClasses}
                disabled={disabled}
                onClick={handler}
            >
                {labelButton}
            </Button>

            {isOpen && createPortal(
                <div
                    className={classNames("dropdown__body", dropDownListClasses, { "is-open": isOpen })}
                    onClick={() => {
                        if (!closeYourSelf) {
                            return;
                        }

                        setIsOpen(false);
                    }}
                    ref={dropRef}
                    style={{
                        ...dropSytle,
                        overflow
                    }}
                >
                    {children}
                </div>, document.body)}
        </div>

    );
};

export default DropDown;
