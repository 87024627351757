import classNames from "classnames";
import { Country } from "country-state-city";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { ReactComponent as ChevronRignt } from "../../../assets/img/ico-chevron--next.svg";
import { ReactComponent as ShareIcon } from "../../../assets/img/icon-share.svg";
import TooltipInfo from "../../../components/parts/TooltipInfo";
import { EVENT_TYPE, USER_ROLE } from "../../../constants/enums";
import { explanations } from "../../../constants/explanations";
import { getDateRange, getFullLocation } from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";

const SeminarSingleDefaultAsside = ({ pageData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'seminars' });
    const user = useSelector(getUser);
    if (!pageData) return;

    const dateArray = getDateRange(pageData?.seminarDates)
        ? getDateRange(pageData?.seminarDates).split(",")
        : [];

    const canBuyTicket = !user || user?.role === USER_ROLE.student || user.role === USER_ROLE.guest
    const ticketsLeft = (pageData?.maxGymCapacity || 0) - (pageData?.ticketsPurchased || 0)
    const bookTicketLink = `/book/${pageData?._type === EVENT_TYPE.camp ? "camp/" : ""}${pageData.id}`
    const bookLessonLink = ""
    return (
        <div className="card-shadow px-36 py-32">
            <div className="flex items-center justify-between gap-12 mb-24 pb-24 border-bottom">
                <div className="price-group gap-4">
                    <span className="color-grey">
                        {t('from')} /

                        {" "}
                    </span>

                    <span className="price-group__price">
                        {pageData?.minimalPriceTicket}

                        $
                    </span>
                </div>

                <div className="group-ticket-info">
                    <div className="flex items-center gap-4 color-secondary">
                        <div className="flex">
                            <img src={require("../../../assets/img/icon-ticket.svg").default} alt="tickets" />
                        </div>
                        {pageData?.ticketsPurchased} &nbsp;
                    </div>

                    / {pageData?.maxGymCapacity}
                </div>
            </div>

            <div
                className={classNames(`flex items-center justify-between gap-12 mb-24`, { "pb-24 border-bottom": canBuyTicket })}
            >
                <span className="card-seminar__info-date card-seminar__info-date--lg">
                    {
                        dateArray && dateArray.length > 0 && (
                            <span className="card-seminar__info-date card-seminar__info-date--lg">
                                {dateArray[0]}
                                {", "}
                                &nbsp;
                                <span className="color-secondary">
                                    {dateArray[1]}
                                </span>
                            </span>
                        )}
                </span>
            </div>
            <div className="pb-24 mb-24 border-bottom">
                <div data-tooltip-id={"buy-ticket-link"}>
                    <Link
                        to={bookTicketLink}
                        className={classNames("btn btn--primary btn--lg w-full gap-10 mb-8", {
                            "disabled": !ticketsLeft || !canBuyTicket
                        })}
                        onClick={e => (!canBuyTicket || !ticketsLeft) && e.preventDefault()}
                        disabled={!canBuyTicket}
                    >
                        <span className="info fs-20">
                            Buy Ticket
                        </span>
                        <ChevronRignt />
                    </Link>
                </div>
                {
                    !canBuyTicket && (
                        <Tooltip
                            id={"buy-ticket-link"}
                            content={"You can’t buy a ticket as an Instructor/Academy"}
                            style={{ maxWidth: 400 }}
                        />
                    )
                }

                {/* TODO */}
                <div data-tooltip-id={"book-lesson-link"}>
                    <Link
                        to={bookLessonLink}
                        className={classNames("btn btn--default btn--lg w-full gap-10", {
                            "disabled": !ticketsLeft || !canBuyTicket
                        })}
                        disabled={!canBuyTicket}
                    >
                        <span className="info fs-20">
                            Book private lesson
                        </span>
                        <ChevronRignt />
                    </Link>
                </div>
                {
                    !canBuyTicket && (
                        <Tooltip
                            id={"book-lesson-link"}
                            content={"You can’t book private lesson as an Instructor/Academy"}
                            style={{ maxWidth: 400 }}
                        />
                    )
                }
            </div>

            <button
                className="btn color-secondary gap-10 w-full mb-24 pb-24 border-bottom"
            >
                <ShareIcon />

                <span className="underline fw-500 not-italic tt-none">
                    {t('share')}
                </span>
            </button>

            <div className="ticket-info-body border-bottom mb-24 pb-24">
                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('langs')}

                        {" "}
                    </span>

                    <span>
                        {pageData?.languages?.join(", ")}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('host')}
                        {" "}
                    </span>

                    <span>
                        {pageData?.userAcademy?.academyProfile?.name}
                    </span>
                </div>

                <div className="ticket-info-body__item">
                    <span className="color-text">
                        {t('address')}
                    </span>

                    <span>
                        {" "}

                        {
                            getFullLocation([Country.getCountryByCode(pageData?.country)?.name, pageData?.address])
                        }
                    </span>
                </div>

            </div>

            <div className="row-group justify-between gap--sm">
                <span className="block color-text mb-12">
                    {t('attending')}
                </span>
            </div>

            <div className="flex items-center border-bottom mb-24 pb-24">
                <ul className="user-list flex-auto">
                    <li className="user-item">
                        <div className="user-item__img">
                            <img src={require("../../../assets/img/ava-students-1.png")} />
                        </div>
                    </li>

                    <li className="user-item">
                        <div className="user-item__img">
                            <img src={require("../../../assets/img/ava-students-1.png")} />
                        </div>
                    </li>

                    <li className="user-item">
                        <div className="user-item__img">
                            <img src={require("../../../assets/img/ava-students-1.png")} />
                        </div>
                    </li>
                </ul>

                <div className="flex flex-col">
                    <span className="fs-14 fw-500">
                        {pageData?.ticketsPurchased}
                        {" / "}
                        {pageData?.maxGymCapacity}
                    </span>

                    <span className="fs-14 color-text">
                        {t('students')}
                    </span>
                </div>
            </div>

            <div className="ticket-info-body__item">
                <span className="color-text">
                    {t('min_attendance')}
                </span>

                <span className="flex items-center justify-between gap-4 flex-auto">
                    <div className="flex items-center gap-4">
                        <div className="ico ico-18">
                            <svg
                                fill="none"
                                height="18"
                                viewBox="0 0 18 18"
                                width="18"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9 1.5C4.8645 1.5 1.5 4.8645 1.5 9C1.5 13.1355 4.8645 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.8645 13.1355 1.5 9 1.5ZM9 15C5.69175 15 3 12.3082 3 9C3 5.69175 5.69175 3 9 3C12.3082 3 15 5.69175 15 9C15 12.3082 12.3082 15 9 15Z"
                                    fill="#9747FF"
                                />

                                <path
                                    d="M7.50005 10.1902L5.7758 8.46898L4.7168 9.53098L7.50155 12.3097L12.531 7.28023L11.4705 6.21973L7.50005 10.1902Z"
                                    fill="#9747FF"
                                />
                            </svg>

                        </div>

                        <div>
                            {pageData?.minAttendance}

                            {" "}

                            {t('students')}
                        </div>
                    </div>
                    <TooltipInfo content={explanations.minimumAttendance} />
                </span>
            </div>

        </div>
    );
};

export default SeminarSingleDefaultAsside;
