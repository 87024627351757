import { Country } from "country-state-city";
import { useSelector } from "react-redux";
import {
    getDateRange, getFullLocation, getImageSrc, getTrainingStyle
} from "../../../helpers/utils";
import { getUser } from "../../../redux/slices/applicationSlice";
import DropDown from "../../ui/DropDown";

const CardTour = ({ data = {}, onDelete }) => {
    const user = useSelector(getUser);
    const trainingStyle = getTrainingStyle(data?.traningStyle);
    const dateArray = getDateRange(data?.seminarDates)
        ? getDateRange(data?.seminarDates).split(",")
        : [];
    const cover = data?.photos.find((item) => item?.name === data?.coverFilename);

    const getLocation = (data) => {
        if (!data?.location) {
            return "Location not specified";
        }

        const location = data?.location
            ? Country.getCountryByCode(data?.location)?.name
            : data?.location;

        return `${location}, ${data?.address} `;
    };

    return (
        <div className="card-seminar">
            <div className="card-seminar__img-block">
                <div className="card-seminar__img-info">
                    {
                        dateArray && dateArray.length > 0 && (
                            <span className="card-seminar__info-date">
                                {dateArray[0]}

                                {", "}
&nbsp;
                                <span className="color-primary">
                                    {dateArray[1]}
                                </span>
                            </span>
                        )}

                    <span className="card-seminar__gi ml-auto">
                        {trainingStyle[0]?.icon}

                        <span>
                            {trainingStyle[0]?.title}
                        </span>
                    </span>
                </div>

                <div className="card-seminar__img">
                    {cover
                        ? (
                            <img
                                src={getImageSrc(cover)}
                                alt="cover"
                            />
                        )


                        : (
                            <img
                                src={require("../../../assets/img/photo-placeholder.svg").default}
                                alt=""
                            />
                        )}
                </div>
            </div>

            <div className="card-seminar__body">
                <div className="card-seminar__group-city">
                    <div className="flex">
                        <img
                            alt="cover"
                            src={require("../../../assets/img/icon-decor-city.svg").default}
                        />
                    </div>

                    <span className="truncate">
                        {user?.role === "academy"
                            ? getLocation(data)
                            : getFullLocation([data.country, data.city])}
                    </span>
                </div>

                <h4 className="heading ">
                    {data?.seminarName || data?.name}
                </h4>

                <div className="type-camp">
                    <span className="color-grey">
                        Type:
                    </span>

                    <span className="fw-500">
                        Pending Seminar
                    </span>
                </div>

            </div>

            <div className="card-seminar__footer">
                <div className="price-group">
                    <span className="price-group__price">
                        0
                    </span>

                    <span className="color-grey">
                        / requests
                    </span>
                </div>

                <div className="row-group gap--xs">
                    <button className="btn btn--primary btn--md radius">
                        Preview
                    </button>

                    <DropDown
                        buttonClasses="btn btn--md btn--square"
                        dropDownListClasses="px-16px py-3"
                        labelButton={(
                            <span className="ico">
                                <svg
                                    fill="none"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    width="22"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.9607 10.9995C16.9607 11.2528 16.7555 11.4581 16.5022 11.4581C16.249 11.4581 16.0437 11.2528 16.0437 10.9995C16.0437 10.7463 16.249 10.541 16.5022 10.541C16.7555 10.541 16.9607 10.7463 16.9607 10.9995"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M11.4586 10.9995C11.4586 11.2528 11.2533 11.4581 11 11.4581C10.7468 11.4581 10.5415 11.2528 10.5415 10.9995C10.5415 10.7463 10.7468 10.541 11 10.541C11.2533 10.541 11.4586 10.7463 11.4586 10.9995"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />

                                    <path
                                        d="M5.95611 10.9995C5.95611 11.2528 5.75082 11.4581 5.49759 11.4581C5.24435 11.4581 5.03906 11.2528 5.03906 10.9995C5.03906 10.7463 5.24435 10.541 5.49759 10.541C5.75082 10.541 5.95611 10.7463 5.95611 10.9995"
                                        stroke="#02020A"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.2"
                                    />
                                </svg>
                            </span>
                        )}
                    >
                        <button
                            className="btn btn--danger btn--sm"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                    </DropDown>

                </div>
            </div>
        </div>
    );
};

export default CardTour;
