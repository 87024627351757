import {
    useEffect, useState
} from "react";
import DatePicker from "../../../components/ui/formElements/DatePicker";
import moment from "moment";
import {
    eachDayOfInterval, format,
} from "date-fns";
import classNames from "classnames";

const DateRangeSelect = ({ handleChange, disabled, isErrorsShown, errors, dates, startAt, endAt }) => {
    // const [startDate, setStartDate] = useState(dates?.length ? dates[0].date : null);
    // const [endDate, setEndDate] = useState(dates?.length ? dates[dates.length - 1].date : null);

    // useEffect(() => {
    //     if (!(startDate && endDate)) return;
        
    //     handleChange(eachDayOfInterval({
    //         start: startDate,
    //         end: endDate
    //     }).map((date) => ({
    //         date: format(date, "yyyy-MM-dd"),
    //         startTime: null,
    //         endTime: null
    //     })));
    // }, [startDate, endDate]);


    // useEffect(() => {
    //     setStartDate(dates?.length ? dates[0].date : null);
    //     setEndDate(dates?.length ? dates[dates.length - 1].date : null);
    // }, [dates])

    return (
        <div className={classNames("schedule-group", { "block-disabled": disabled })} >
            <div className="input-date-time-group">
                {/* <div className="flex-1">
                    <DatePicker
                        label="Start Date"
                        minDate={moment().toDate()}
                        placeholderText="Select Start Date"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        // excludeDates={excludeDates}
                        error={!disabled && isErrorsShown && errors?.availableDates && !startDate}
                    />
                </div> */}
                <div className="flex-1">
                    <DatePicker
                        label="Start Date"
                        minDate={moment().toDate()}
                        placeholderText="Select Start Date"
                        selected={startAt}
                        onChange={(date) => handleChange("startAt", new Date(date).toISOString())}
                        // excludeDates={excludeDates}
                        error={!disabled && isErrorsShown && errors?.startAt && !startAt}
                    />
                </div>

                <span
                    className="input-delimiter"
                    style={{ marginTop: 26 }}
                />

                {/* <div className="flex-1">
                    <DatePicker
                        label="End Date"
                        minDate={startDate}
                        placeholderText="Select End Date"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        // excludeDates={excludeDates}
                        error={!disabled && isErrorsShown && errors?.availableDates && !endDate}
                    />
                </div> */}
                <div className="flex-1">
                    <DatePicker
                        label="End Date"
                        minDate={startAt}
                        placeholderText="Select End Date"
                        selected={endAt}
                        onChange={(date) => handleChange("endAt", new Date(date).toISOString())}
                        // excludeDates={excludeDates}
                        error={!disabled && isErrorsShown && errors?.endAt && !endAt}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateRangeSelect;
