import React, { useState } from "react";
import Accordeon from "../ui/accordeons/Accordeon";
import Button from "../ui/buttons/Button";
import { useTranslation } from "react-i18next";
import findValue from "../../helpers/findValue";

const FQA = ({ pageData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'faq' });
    const getValue = (key) => findValue(pageData?.blocks, key);
    
    const [showMore, setShowMore] = useState(false);
    const [visibleCount, setVisibleCount] = useState(7);  // Start with 7 questions visible

    const fqaData = getValue('faq_contents_list')?.data || new Array(20).fill(undefined)
        .map((_, index) => ({
            title: t(`faq_${index + 1}_title`),
            description: t(`faq_${index + 1}_descr`)
        }));
    
    const handleLoadMore = () => {
        if (visibleCount >= fqaData.length) {
            setVisibleCount(7); // Reset to the default view (7 items)
        } else {
            setVisibleCount(visibleCount + 5); // Load 5 more items
        }
    };

    const filteredData = fqaData.slice(0, visibleCount);
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="section faq">
            <div className="container">
                <h2 className="heading text-center mb-44">
                    {getValue('faq_title') || t('title')}
                </h2>

                {filteredData.map((item, index) => (
                    <Accordeon
                        className="w-full"
                        index={index}
                        isActive={activeIndex === index}
                        key={index}
                        onClick={() => toggleAccordion(index)}
                        onlyOneIsOpen={true}
                        title={item.title}
                    >
                        {item?.content || item?.description}
                    </Accordeon>
                ))}

                <Button
                    className="btn btn--primary btn--md show-more__button"
                    onClick={handleLoadMore}
                >
                    <span className="info">
                        {visibleCount >= fqaData.length ? "Show less" : "Load more"}
                    </span>
                </Button>
            </div>
        </section>
    );
};

export default FQA;
