import {
    useEffect, useMemo, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Outlet, useParams
} from "react-router-dom";
import PageLoader from "../../../components/ui/PageLoader";
import {
    calcTotalHours,
    checkErrors, getTrainingStyle
} from "../../../helpers/utils";
import {
    getEventType,
    getSeminarFormData, resetSeminarFormData, setSeminarFormData
} from "../../../redux/slices/planningSlice";
import { getInstructorInfoFetch } from "../../../redux/thunks/instructorsThunks";
const initialData = {
    dates: [],
    style: "",
    peopleAttending: "",
    message: "",
    eventId: null,
};

const RequestPrivateSessionWrapper = () => {
    const { instructorId } = useParams();
    const dispatch = useDispatch();
    const [errorConfig, setErrorConfig] = useState({});
    const { instructorInfo, isLoading } = useSelector((state) => state.instructors);
    const formData = useSelector(getSeminarFormData);
    const eventType = useSelector(getEventType)
    const [errors, setErrors] = useState({});
    const { seminars } = useSelector((state) => state.seminars);
    const { camps } = useSelector((state) => state.planCamps);

    const eventsTypes = {
        seminar: seminars,
        plancamp: camps
    }
    const selectedEvent = formData?.eventId ? eventsTypes[eventType].find(event => event.id === formData.eventId) : null

    const handleChange = (name, value) => {
        dispatch(setSeminarFormData({
            name,
            value
        }));
    };

    useEffect(() => {
        setErrors(checkErrors(formData, errorConfig));
    }, [formData, errorConfig]);
    useEffect(() => {
        dispatch(resetSeminarFormData(initialData));
    }, []);
    useEffect(() => {
        if (!instructorId) return;

        dispatch(getInstructorInfoFetch(instructorId));
    }, [instructorId]);



    const totalHours = useMemo(() => calcTotalHours(formData.dates), [formData.dates])
    
    if (!instructorInfo) {
        return null;
    }


    const { instructorProfile, generalInstructorInfo, privateSession } = instructorInfo;

    const totalPrice = privateSession?.pricePerSession * totalHours

    return (
        <section className="section-42">
            <div className="container">
                {
                    formData && instructorInfo && !isLoading
                        ? (
                            <div className="section-row-block">
                                <div className="section-row-block__body">
                                    <Outlet
                                        context={[
                                            formData,
                                            handleChange,
                                            errors,
                                            setErrorConfig,
                                            instructorInfo
                                        ]}
                                    />
                                </div>

                                <div className="section-row-block__aside">
                                    <div className="card-shadow px-36 py-32">
                                        <div className="ticket-info-body ticket-info-body--no-border">
                                            {selectedEvent && (
                                                <div className="ticket-info-body__item">
                                                    <span className="color-text">
                                                        Academy:
                                                    </span>

                                                    <span className="fw-500">
                                                        {selectedEvent.userAcademy.academyProfile.name || selectedEvent.user.academyProfile.name}

                                                        {" "}
                                                    </span>
                                                </div>
                                            )}

                                            <div className="ticket-info-body__item">
                                                <span className="color-text">
                                                    Instructor:
                                                </span>

                                                <span className="fw-500">
                                                    {instructorProfile.fullName}
                                                </span>
                                            </div>

                                            <div className="ticket-info-body__item">
                                                <span className="color-text">
                                                    Address::
                                                </span>

                                                <span className="fw-500">
                                                    {selectedEvent ? selectedEvent.address
                                                        :
                                                        `${privateSession?.address}, ${privateSession?.gym}`
                                                    }

                                                </span>
                                            </div>

                                            <div className="ticket-info-body__item">
                                                <span className="color-text">
                                                    Private Lesson Price:
                                                </span>

                                                <span className="fw-500">
                                                    $ {totalPrice}
                                                </span>
                                            </div>

                                            <div className="ticket-info-body__item">
                                                <span className="color-text">
                                                    Instructor style:
                                                </span>

                                                <span className="fw-500">
                                                    {getTrainingStyle(privateSession?.traningStyle)?.[0]?.title}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )


                        : <PageLoader />
                }
            </div>
        </section>
    );
};

export default RequestPrivateSessionWrapper;
