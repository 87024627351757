import classNames from "classnames";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../components/ui/buttons/Button";
import Loader from "../components/ui/Loader";
import { getImageSrc } from "../helpers/utils";
import {
    deleteNotificationFetch, getAllNotificationsFetch, readNotificationsFetch
} from "../redux/thunks/notificationsThunks";
import { ReactComponent as ChevronNextIcon } from "../assets/img/ico-chevron--next.svg";
import { ReactComponent as DeleteIcon } from "../assets/img/ico-trash.svg";
import { PaginationTemplate } from "../components/parts/PaginationTemplate";
import { formatDistanceToNow } from "date-fns";

export const NotificationItem = ({ data, isAllReading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isReading, setIsReading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleNavigate = (id, instructorId) => {
        navigate(`/instructors/${instructorId}`);
        dispatch(readNotificationsFetch({ body: { ids: [id] } }));
    };

    const handleRead = () => {
        setIsReading(true);
        dispatch(readNotificationsFetch({ body: { ids: [data.id] } })).finally(() => setIsReading(false));
    };

    const handleDelete = () => {
        setIsDeleting(true);
        dispatch(deleteNotificationFetch(data.id)).finally(() => setIsDeleting(false));
    };

    const requestTypes = ['request_seminar', 'request_plancamp', 'request_private_lesson']
    const isRequest = requestTypes.includes(data?.type);

    const handleClick = () => {
        const id = data.id
        if (!id) return
        let link;
        if (requestTypes.includes(data?.type) && data?.payload?.chatId) {
            link = `/chat/${data?.payload?.chatId}`
        } else if (data?.payload?.instructorId) {
            link = `/instructors/${data?.payload?.instructorId}`
        } else {
            link = `/requests`
        }
        handleRead(id)
        navigate(link);
    }

    return (
        <div onClick={handleClick} className={classNames("notification-item border-bottom", { "--readed": data.isReaded })} style={{ cursor: "pointer" }}>
            <div className="notification-item__body">
                <div className="col-group gap--sm flex-auto">
                    <div className="row-group gap--md">
                        <div className="image-wrapper avatar">
                            <img
                                alt="instructor"
                                src={getImageSrc(data?.payload?.photo || data?.payload?.user?.photo)}
                            />
                        </div>
                        <div className="col-group gap--xs">
                            <h6>{data?.payload?.fullName || data?.payload?.user?.fullName}</h6>
                            <p className="color-text">
                                {data.message}
                            </p>
                        </div>
                    </div>
                </div>
                {formatDistanceToNow(data?.createdAt ? new Date(data.createdAt) : new Date(), { addSuffix: true })}
            </div>

            <div className="notification-item__actions">
                <Button
                    className="underline color-danger btn--md btn--square"
                    isLoading={isDeleting}
                    onClick={handleDelete}
                >
                    <DeleteIcon />
                </Button>
                {/* {
                    isRequest && data?.payload?.chatId ? (
                        <Link
                            to={`/chat/${data?.payload?.chatId}`}
                            className="btn btn--primary btn--sm w-fit"
                            style={{ width: 228 }}
                            onClick={handleRead}
                        >
                            <span className="info">
                                Open Chat
                            </span>

                            <span className="ico ico--20">
                                <ChevronNextIcon />
                            </span>
                        </Link>
                    ) : data?.payload?.instructorId ? (
                        <Button
                            className="btn--primary btn--sm w-fit"
                            onClick={() => {
                                handleRead()
                                handleNavigate(data.id, data?.payload?.instructorId)
                            }}
                            style={{ width: 228 }}
                        >
                            <span className="info">
                                See Profile
                            </span>

                            <span className="ico ico--20">
                                <ChevronNextIcon />
                            </span>
                        </Button>
                    ) : (
                        <Link
                            to={`/requests`}
                            className="btn btn--primary btn--sm w-fit"
                            style={{ width: 228 }}
                            onClick={handleRead}
                        >
                            <span className="info">
                                Open Requests
                            </span>

                            <span className="ico ico--20">
                                <ChevronNextIcon />
                            </span>
                        </Link>
                    )
                } */}
            </div>
            <span className="blue-indicator" style={{ opacity: data.isReaded ? "0" : "1" }}></span>
        </div>
    );
};

const NotificationsPage = () => {
    const dispatch = useDispatch();
    const [isAllReading, setIsAllReading] = useState(false);
    const { notifications, isLoading, pagination } = useSelector((state) => state.notifications);
    const [params, setParams] = useSearchParams({
        sort_by: "isReaded",
        sort_as: "asc"
    })

    const handleReadAll = () => {
        setIsAllReading(true);
        dispatch(readNotificationsFetch({ readUnreadedAll: true })).finally(() => setIsAllReading(false));
    };

    useEffect(() => {
        dispatch(getAllNotificationsFetch(params.toString()));
    }, [params]);

    const handlePageChange = (page) => {
        setParams(prev => {
            prev.set("page", page);

            return prev
        })
    }

    return (
        <section className="col-group flex-auto py-5">
            <div className="container col-group flex-auto">
                <div className="row-group justify-between gap--md mb-24">
                    <h3 className="heading">
                        Notifications
                    </h3>

                    {
                        !!notifications?.length && (
                            <Button
                                className="btn--primary btn--md"
                                isLoading={isAllReading}
                                onClick={handleReadAll}
                            >
                                Read all
                            </Button>
                        )}
                </div>

                {
                    notifications?.length && !isLoading
                        ? (
                            <div className="col-group">
                                {
                                    notifications.map((item) => (
                                        <NotificationItem
                                            key={item.id}
                                            data={item}
                                            isAllReading={isAllReading}
                                        />
                                    )



                                    )
                                }
                            </div>
                        )


                        : isLoading
                            ? (
                                <div className="m-auto">
                                    <Loader />
                                </div>
                            )


                            : (
                                <div className="m-auto">
                                    <h5 className="heading">
                                        There is no notifications
                                    </h5>
                                </div>
                            )


                }
                <PaginationTemplate
                    {...pagination}
                    onPageChange={handlePageChange}
                    className={"mt-auto pt-24"}
                />
            </div>
        </section>
    );
};

export default NotificationsPage;
