import { Country } from "country-state-city";
import {
    useEffect, useState
} from "react";
import {
    useDispatch, useSelector
} from "react-redux";
import {
    Outlet, useLocation, useNavigate, useParams, useSearchParams
} from "react-router-dom";
import api from "../../../api";
import PageLoader from "../../../components/ui/PageLoader";
import { EVENT_STATUS, EVENT_STEP, USER_ROLE } from "../../../constants/enums";
import { checkErrors, getFullLocation } from "../../../helpers/utils";
import { getAcademyGeneralInfo } from "../../../redux/slices/academyGeneralInfoSlice";
import { getGeneralInfo, getUser } from "../../../redux/slices/applicationSlice";
import {
    getSeminarFormData, resetSeminarFormData, setSeminarFormData
} from "../../../redux/slices/planningSlice";
import { getAcademyGeneralInfoFetch } from "../../../redux/thunks/academyGeneralInfoThunks";
import { getInstructorGeneralInfoFetch } from "../../../redux/thunks/applicationThunks";
import { getChatByIdFetch } from "../../../redux/thunks/chatsThunks";
import { getSeminarsById } from "../../../redux/thunks/seminarsThunks";
import { covertDateFormat } from "../parts/EventDatesSelect";
import PlanningSteps from "../parts/PlanningSteps";
import SeminarCardPreview from "../parts/SeminarCardPreview";

const menuItems = [
    {
        title: "Time & Date",
        to: "",
        icon: "date",
        id: EVENT_STEP.date
    }, {
        title: "Seminar Information",
        to: "seminar-info",
        icon: "info",
        id: EVENT_STEP.details
    }, {
        title: "Design & Text",
        to: "design-text",
        icon: "design",
        editableWhenActive: true,
        id: EVENT_STEP.design
    }
];

const initialDataAcademy = {
    seminarMinPrice: "",
    seminarMaxPrice: "",
    sharedProfit: "",
    address: "",
    coutry: "",
    continent: "all",
    specificBelt: "",
    isAvailablePrivateLesson: false,
    minAttendanceDeadline: "",
    traningStyle: "",
    targetAudience: "",
    allowUnder18: false,
    availableDates: [],
    seminarName: "",
    teachingStyles: [],
    maxGymCapacity: "",
    summary: "",
    instructors: [],
    photos: [],
    coverFilename: "",
    languages: [],
    isPrivate: false
};
const initialDataInstructor = {
    seminarMinPrice: "",
    seminarMaxPrice: "",
    sharedProfit: "",
    country: "",
    continent: "all",
    city: "",
    availableDates: [],
    isAvailablePrivateLesson: false,
    targetAudience: "",
    teachingStyles: [],
    allowUnder18: false,
    seminarName: "",
    summary: "",
    instructors: [],
    photos: [],
    coverFilename: "",
    isPrivate: false
};
const initialPreviewFields = {
    availableDates: [],
    location: "",
    seminarName: "",
    instructors: [],
    photo: "",
    fullName: "",
    minimalPriceTicket: "",
    cover: null,
    traningStyle: "",
}
const PlanSeminarWrapper = ({ type }) => {
    const { pathname } = useLocation();
    const { seminarId, chatId } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorConfig, setErrorConfig] = useState({});
    const [isInCountry, setIsInCountry] = useState(false);
    const [isInCountryCalculating, setIsInCountryCalculating] = useState(false);
    const user = useSelector(getUser);
    const { seminarDetails, isLoading } = useSelector((state) => state.seminars);
    const [searchParams] = useSearchParams();
    const formData = useSelector(getSeminarFormData);
    const generalInfo = useSelector(getGeneralInfo);
    const [errors, setErrors] = useState({});
    const [preview, setPreview] = useState(initialPreviewFields);

    useEffect(() => {
        if (!chatId) return;

        setIsInCountryCalculating(true);
        dispatch(getChatByIdFetch({ chatId })).unwrap()
            .then((res) => {
                const users = res?.data?.users;

                if (users?.length) {
                    const promises = users.map((user) => {
                        if (user?.role === "instructor") {
                            return api.instructors.getInstructorInfo(user.id);
                        } else {
                            return api.academyGeneralInfo.getAcademyById(user.id);
                        }
                    });

                    Promise.all(promises).then((res) => {
                        const countries = res.map((res) => {
                            const user = res.data;

                            return user?.generalAcademyInfo?.country || user?.generalInstructorInfo?.country;
                        });

                        setIsInCountry(countries[0] === countries[1]);
                    });
                }
            })
            .finally(() => setIsInCountryCalculating(false));
    }, [chatId, navigate]);

    const handlePreviewChange = (name, value) => {
        setPreview((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleChange = (name, value) => {
        dispatch(setSeminarFormData({ name, value }));
        if (preview.hasOwnProperty(name)) {

            handlePreviewChange(name, value);
        }
    };

    function getStartDate(dates) {
        return dates?.length
            ? seminarDetails.seminarDates[0]?.date
            : null;
    }

    function calculateMinAttendanceDeadline(seminarDate, isInCountry) {
        const seminarDateObj = new Date(seminarDate);
        const deadlineOffset = isInCountry
            ? 7
            : 21; // 1 week for in-country, 3 weeks for out-of-country

        seminarDateObj.setDate(seminarDateObj.getDate() - deadlineOffset);
        const minAttendanceDeadline = seminarDateObj.toISOString();

        return minAttendanceDeadline;
    }

    useEffect(() => {
        if (user?.role === "academy" && generalInfo) {
            setPreview(prev => ({
                ...prev,
                fullName: user?.profile?.name || "",
                photo: user?.profile?.photo || "",
            }))
        }
        if (user?.role === USER_ROLE.instructor && generalInfo) {
            setPreview(prev => ({
                ...prev,
                fullName: user?.profile?.fullName || "",
                photo: user?.profile?.photo || "",
                location: getFullLocation([Country.getCountryByCode(generalInfo?.country)?.name || generalInfo?.country || "", generalInfo?.addressLine]),
            }))
        }
    }, [user, generalInfo, type]);

    useEffect(() => {
        if (user?.role === USER_ROLE.academy) {
            dispatch(getAcademyGeneralInfoFetch());
        }
        if (user?.role === USER_ROLE.instructor) {
            dispatch(getInstructorGeneralInfoFetch())
        }
    }, [user, type]);

    useEffect(() => {
        setErrors(checkErrors(formData, errorConfig));
    }, [formData, errorConfig]);

    useEffect(() => {
        if (!seminarId) return;

        dispatch(getSeminarsById(seminarId));
    }, [seminarId]);

    useEffect(() => {
        if (seminarId && isInCountryCalculating) return;

        if (seminarId && seminarDetails && !isLoading) {
            if (location.state?.previousLocation !== "/template-editor") {
                const minAttendanceDeadline = calculateMinAttendanceDeadline(getStartDate(seminarDetails.seminarDates, isInCountry));

                if (seminarDetails?.user?.role === "academy") {
                    const initialDataAcademy = {
                        seminarMinPrice: seminarDetails?.seminarMinPrice ?? "",
                        seminarMaxPrice: seminarDetails?.seminarMaxPrice ?? "",
                        sharedProfit: seminarDetails?.sharedProfit,
                        address: seminarDetails?.address,
                        coutry: seminarDetails?.coutry,
                        continent: seminarDetails?.continent,
                        specificBelt: seminarDetails?.specificBelt,
                        isAvailablePrivateLesson: seminarDetails?.isAvailablePrivateLesson,
                        traningStyle: seminarDetails?.traningStyle,
                        targetAudience: seminarDetails?.targetAudience,
                        allowUnder18: seminarDetails?.allowUnder18,
                        availableDates: seminarDetails.seminarDates.map((date) => covertDateFormat(date)),
                        seminarName: seminarDetails?.seminarName,
                        teachingStyles: seminarDetails?.teachingStyles,
                        maxGymCapacity: seminarDetails?.maxGymCapacity,
                        summary: seminarDetails?.summary,
                        instructors: seminarDetails?.instructors,
                        languages: seminarDetails?.languages,
                        isPrivate: seminarDetails?.isPrivate,
                        minAttendance: seminarDetails?.minAttendance,
                        minAttendanceRequirement: seminarDetails?.minAttendanceRequirement,
                        minAttendanceDeadline,
                        minimalPriceTicket: seminarDetails.minimalPriceTicket,
                        instructorPrice: "",
                        coverFilename: seminarDetails?.photos?.find((photo) => photo?.id === seminarDetails?.coverId)?.originalname,
                        photos: seminarDetails?.photos,
                    };
                    handlePreviewChange("location", `${Country.getCountryByCode(seminarDetails.country)?.name || seminarDetails.country} ${seminarDetails.address}`);
                    handlePreviewChange("availableDates", seminarDetails.seminarDates);
                    handlePreviewChange("instructors", seminarDetails?.instructors.map(item => ({
                        id: item.user?.id,
                        fullName: item.user?.instructorProfile?.fullName
                    })));

                    dispatch(resetSeminarFormData(initialDataAcademy));
                }

                if (seminarDetails?.user?.role === "instructor") {
                    const initialData = {
                        seminarMinPrice: seminarDetails.seminarMinPrice ?? "",
                        seminarMaxPrice: seminarDetails.seminarMaxPrice ?? "",
                        city: seminarDetails.city ?? "",
                        country: seminarDetails.country ?? "",
                        continent: seminarDetails.continent ?? "",
                        traningStyle: seminarDetails.traningStyle ?? "",
                        maxGymCapacity: seminarDetails.maxGymCapacity?.toString() ?? "",
                        targetAudience: seminarDetails.targetAudience ?? "any",
                        allowUnder18: seminarDetails.allowUnder18 ?? false,
                        specificBelt: seminarDetails.specificBelt ?? "",
                        seminarName: seminarDetails.seminarName ?? "",
                        summary: seminarDetails.summary ?? "",
                        teachingStyles: seminarDetails.teachingStyles ?? [],
                        availableDates: seminarDetails.seminarDates.map((date) => covertDateFormat(date)),
                        privateSessionDates: seminarDetails?.privateSessionDates?.map((date) => ({
                            date: date.date ?? "",
                            startTime: date.startTime ?? "",
                            endTime: date.endTime ?? ""
                        })),
                        languages: seminarDetails.languages ?? [],
                        photos: seminarDetails.photos ?? [],
                        minimalPriceTicket: seminarDetails.minimalPriceTicket,
                        isAvailablePrivateLesson: seminarDetails.isAvailablePrivateLesson ?? false,
                        minAttendanceRequirement: "",
                        minAttendanceDeadline,
                        minAttendance: seminarDetails.minAttendance,
                        sharedProfit: seminarDetails.sharedProfit?.toString() ?? "",
                        coverFilename: seminarDetails?.photos?.find((photo) => photo?.id === seminarDetails?.coverId)?.originalname,
                        instructorPrice: seminarDetails?.instructorPrice
                    };

                    handlePreviewChange("location", `${Country.getCountryByCode(seminarDetails.country)?.name || seminarDetails.country} ${seminarDetails.city}`);
                    handlePreviewChange("availableDates", seminarDetails.seminarDates);
                    handlePreviewChange("instructors", seminarDetails?.instructors.map(item => ({
                        id: item.user?.id,
                        fullName: item.user?.instructorProfile?.fullName
                    })));

                    dispatch(resetSeminarFormData(initialData));
                }
                handlePreviewChange("cover", seminarDetails.cover);

            }
        }

        if (!seminarId && user) {
            if (location.state?.previousLocation !== "/template-editor") {
                dispatch(resetSeminarFormData(user?.role === "academy"
                    ? initialDataAcademy
                    : initialDataInstructor));
                setPreview(initialPreviewFields)
                if (searchParams.get("tour")) {
                    localStorage.setItem("isTour", true);
                } else {
                    localStorage.setItem("isTour", false);
                }

                navigate("/plan-seminar");
            }
        }
    }, [seminarDetails, user, isInCountryCalculating, type]);

    const [steps, setSteps] = useState(menuItems)

    useEffect(() => {
        if (seminarId) {
            setSteps([...menuItems, {
                title: "Booking Specific Terms",
                to: "booking",
                icon: "booking"
            }])
        } else {
            setSteps(menuItems)
        }
    }, [seminarId, type]);

    return (
        <>
            <PlanningSteps
                step={seminarDetails?._eventRequest?.academyRequest?.step}
                isCreated={seminarId}
                isActive={seminarId && seminarDetails?.status === EVENT_STATUS.active}
            />

            <section className="section-42">
                <div className="container">
                    {
                        formData && !isLoading
                            ? (
                                <div className="section-row-block plan-block">
                                    <div className="section-row-block__body">
                                        <Outlet
                                            context={[
                                                formData,
                                                handleChange,
                                                errors,
                                                handlePreviewChange,
                                                preview,
                                                setErrorConfig
                                            ]}
                                            steps={menuItems}
                                        />
                                    </div>

                                    {
                                        !pathname.includes("success") && <SeminarCardPreview {...preview} />
                                    }
                                </div>
                            )


                            : <PageLoader />
                    }
                </div>
            </section>
        </>
    );
};

export default PlanSeminarWrapper;
