import { capitalize } from "lodash";
import Button from "../../../../components/ui/buttons/Button";
import DropDown from "../../../../components/ui/DropDown";
import SelectOne from "../../../../components/ui/formElements/SelectOne";
import {
    sharedProfits,
    teachingStyles, trainingStyle
} from "../../../../constants";
import { CardDetails } from "../CardDetails";
import { Step } from "../Step";


import { ReactComponent as DoneIcon } from "../../../../assets/img/done.svg";
import { useDispatch } from "react-redux";
import { getTeachStyle } from "../../../../helpers/utils";
import { updateCampFetch } from "../../../../redux/thunks/planCampThuncks";
import { useState } from "react";

export const StepDetails = ({
    seminarDetails, currentStatus, status, step, requestId
}) => {
    const dispatch = useDispatch()
    const teachingStyles = getTeachStyle(seminarDetails.teachingStyles).join(", ");
    const [isSharedProfitEditing, setIsSharedProfitEditing] = useState(false)
    const sharedProfitOptions = sharedProfits.map(({ key, title }) => ({
        label: title,
        value: key
    }))
    const totalRevenue = +seminarDetails.minimalPriceTicket * +seminarDetails.minimumAttendance

    const [sharedProfit, setSharedProfit] = useState(seminarDetails?.sharedProfit)

    const instructorRevenue =
        seminarDetails.sharedProfit
            ? totalRevenue * (seminarDetails.sharedProfit / 100)
            : 0;

    const academyRevenue =
        seminarDetails.sharedProfit
            ? totalRevenue - instructorRevenue
            : 0

    const getValueOrDefault = (value, format = (val) => val) => {
        if (value) {
            return format(value);
        }

        return "Not Specified"
    }

    const handleCheck = async () => {
        setIsSharedProfitEditing(true)
        const res = await dispatch(updateCampFetch({
            campId: seminarDetails.id,
            body: { sharedProfit }
        })).unwrap();

        setIsSharedProfitEditing(false)
    };

    return (
        <Step
            currentStatus={currentStatus}
            label="Seminar Details"
            status={status}
            eventId={seminarDetails.id}
            eventType="plancamp"
            step={step}
            requestId={requestId}

        >
            {
                seminarDetails?.sharedProfit ? (
                    <CardDetails
                        isEdited={
                            seminarDetails?.lastEdited?.includes("sharedProfit") ||
                            seminarDetails?.lastEdited?.includes("priceTo") ||
                            seminarDetails?.lastEdited?.includes("instructorPrice") ||
                            seminarDetails?.lastEdited?.includes("minimalPriceTicket") ||
                            seminarDetails?.lastEdited?.includes("maxGymCapacity")
                        }
                        label="Price Informationa"
                    >
                        <div className="row-group justify-between mb-12 pb-24 border-bottom">
                            <p className="fw-500">
                                Shared Profit
                            </p>

                            <p className="color-text row-group">
                                {
                                    !isSharedProfitEditing ?
                                        getValueOrDefault(seminarDetails?.sharedProfit, (v) => v + "%")
                                        : (
                                            <span className="ico ico--xs">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M12,23a9.63,9.63,0,0,1-8-9.5,9.51,9.51,0,0,1,6.79-9.1A1.66,1.66,0,0,0,12,2.81h0a1.67,1.67,0,0,0-1.94-1.64A11,11,0,0,0,12,23Z"
                                                        fill="currentColor"
                                                    >
                                                        <animateTransform
                                                            attributeName="transform"
                                                            dur="0.75s"
                                                            repeatCount="indefinite"
                                                            type="rotate"
                                                            values="0 12 12;360 12 12"
                                                        />
                                                    </path>
                                                </svg>
                                            </span>
                                        )
                                }
                                {!isSharedProfitEditing && (
                                    <DropDown
                                        dropDownListClasses="time-input-menu"
                                        labelButton={
                                            <button
                                                className="btn btn--xs btn--square"
                                            >
                                                <svg
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_302_17827)">
                                                        <path
                                                            d="M5.25 11.8126H2.625C2.50897 11.8126 2.39769 11.7665 2.31564 11.6844C2.23359 11.6024 2.1875 11.4911 2.1875 11.3751V8.93109C2.18755 8.81521 2.23358 8.70409 2.31547 8.6221L9.05953 1.87804C9.14157 1.79605 9.25281 1.75 9.36879 1.75C9.48477 1.75 9.59601 1.79605 9.67805 1.87804L12.122 4.32038C12.204 4.40242 12.2501 4.51366 12.2501 4.62964C12.2501 4.74562 12.204 4.85686 12.122 4.9389L5.25 11.8126Z"
                                                            stroke="#9747FF"
                                                            strokeWidth="1.2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M11.8125 11.8125H5.25"
                                                            stroke="#9747FF"
                                                            strokeWidth="1.2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M7.4375 3.5L10.5 6.5625"
                                                            stroke="#9747FF"
                                                            strokeWidth="1.2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_302_17827">
                                                            <rect
                                                                width="14"
                                                                height="14"
                                                                fill="white"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>
                                        }
                                        position="left"
                                    >
                                        <div className="dropdown-menu">
                                            <div className="row-group gap--sm">
                                                <SelectOne
                                                    onChange={(option) => setSharedProfit(option.value)}
                                                    options={sharedProfitOptions}
                                                    placeholder="Specific Shared Profit"
                                                    value={sharedProfit}
                                                    wrapperClasses="select--outline select--outline-bg input--lg w-full"
                                                />

                                                <Button
                                                    isLoading={isSharedProfitEditing}
                                                    className="done-button drop-close"
                                                    onClick={handleCheck}
                                                >
                                                    <DoneIcon />
                                                </Button>
                                            </div>
                                        </div>
                                    </DropDown>
                                )}

                            </p>
                        </div>

                        <div className="row-group justify-between">
                            <p className="color-text fw-500">
                                Ticket Price
                            </p>

                            <p className="color-text">
                                {getValueOrDefault(seminarDetails?.minimalPriceTicket, (v) => "$" + v)}
                            </p>
                        </div>
                        <div className="row-group justify-between">
                            <p className="color-text fw-500">
                                Min Revenue Instructor
                            </p>

                            <p className="color-text">
                                {getValueOrDefault(instructorRevenue, (v) => "$" + v)}
                            </p>
                        </div>
                        <div className="row-group justify-between">
                            <p className="color-text fw-500">
                                Min Revenue Academy
                            </p>

                            <p className="color-text">
                                {getValueOrDefault(academyRevenue, (v) => "$" + v)}
                            </p>
                        </div>

                        <div className="row-group justify-between">
                            <p className="color-text fw-500">
                                Min Attendance
                            </p>

                            <p className="color-text">
                                {getValueOrDefault(seminarDetails?.minimumAttendance)}
                            </p>
                        </div>

                    </CardDetails>

                ) : (
                    <CardDetails
                        isEdited={
                            seminarDetails?.lastEdited?.includes("priceFrom") ||
                            seminarDetails?.lastEdited?.includes("priceTo") ||
                            seminarDetails?.lastEdited?.includes("instructorPrice") ||
                            seminarDetails?.lastEdited?.includes("minimalPriceTicket") ||
                            seminarDetails?.lastEdited?.includes("maxGymCapacity")
                        }
                        label="Price Informationa"
                    >
                        <div className="row-group justify-between mb-12 pb-24 border-bottom">
                            <p className="fw-500">
                                Price Range
                            </p>

                            <p className="color-text">

                                {`${getValueOrDefault(seminarDetails?.priceFrom, (v) => "$" + v)} - ${getValueOrDefault(seminarDetails?.priceTo, (v) => "$" + v)}`}
                            </p>
                        </div>
                        <div className="row-group justify-between">
                            <p className="color-text fw-500">
                                Instructor Price
                            </p>

                            <p className="color-text">
                                {getValueOrDefault(seminarDetails?.instructorPrice, (v) => "$" + v)}
                            </p>
                        </div>
                        <div className="row-group justify-between">
                            <p className="color-text fw-500">
                                Ticket Price
                            </p>

                            <p className="color-text">
                                {getValueOrDefault(seminarDetails?.minimalPriceTicket, (v) => "$" + v)}
                            </p>
                        </div>
                        <div className="row-group justify-between">
                            <p className="color-text fw-500">
                                Academy Potential Revenue
                            </p>

                            <p className="color-text">
                                {getValueOrDefault((seminarDetails?.minimalPriceTicket * seminarDetails?.maxGymCapacity) - seminarDetails?.instructorPrice, (v) => "$" + v)}
                            </p>
                        </div>

                    </CardDetails>
                )
            }
            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("teachingStyles")}
                label="Teaching Styles"
            >
                {
                    teachingStyles
                        ? (
                            <p className="color-text">
                                {teachingStyles}
                            </p>
                        )


                        : (
                            <p className="color-text mx-auto">
                                No Teaching Styles
                            </p>
                        )


                }
            </CardDetails>
            <CardDetails
                isEdited={
                    seminarDetails?.lastEdited?.includes("languages") ||
                    seminarDetails?.lastEdited?.includes("traningStyle") ||
                    seminarDetails?.lastEdited?.includes("targetAudience") ||
                    seminarDetails?.lastEdited?.includes("maxGymCapacity") ||
                    seminarDetails?.lastEdited?.includes("allowUnder18") ||
                    seminarDetails?.lastEdited?.includes("minAttendance") ||
                    seminarDetails?.lastEdited?.includes("minAttendanceDeadline")
                }
                label="Seminar Information"
            >
                <div className="row-group justify-between">
                    <p className="fw-500">
                        Language
                    </p>

                    <p className="color-text">
                        {seminarDetails?.languages?.length
                            ? languages.find(({ key }) => key === seminarDetails?.languages[0])?.title
                            : null}
                    </p>
                </div>

                <div className="row-group justify-between">
                    <p className="fw-500">
                        Gi or NoGi

                        {" "}
                    </p>

                    <p className="color-text">
                        {trainingStyle.find((item) => item.key === seminarDetails?.traningStyle)?.title}
                    </p>
                </div>

                <div className="row-group justify-between">
                    <p className="fw-500">
                        Open to
                    </p>

                    <p className="color-text">
                        {seminarDetails?.targetAudience && seminarDetails?.targetAudience !== "any"
                            ? capitalize(seminarDetails?.targetAudience)
                            : "Everyone"}
                    </p>
                </div>

                <div className="row-group justify-between">
                    <p className="fw-500">
                        Max Gym Capacity
                    </p>

                    <p className="color-text">
                        {seminarDetails?.maxGymCapacity}
                    </p>
                </div>

                <div className="row-group justify-between">
                    <p className="fw-500">
                        Under the Age of 18
                    </p>

                    <p className="color-text">
                        {seminarDetails?.allowUnder18
                            ? "Allowed"
                            : "Not  Allowed"}
                    </p>
                </div>
                {
                    !seminarDetails?.sharedProfit && (
                        <div className="row-group justify-between">
                            <p className="fw-500">
                                Minimum Attendance
                            </p>

                            <p className="color-text">
                                {seminarDetails?.minimumAttendance}
                            </p>
                        </div>
                    )
                }
                <div className="row-group justify-between">
                    <p className="fw-500">
                        Min Attendance Deadline
                    </p>

                    <p className="color-text">
                        {getValueOrDefault(seminarDetails?.minAttendanceDeadline)}
                    </p>
                </div>
            </CardDetails>


        </Step>
    );
};

// export const StepDetails = ({
//     seminarDetails, currentStatus, status, step, requestId 
// }) => {
//     const instructorCut = () => {
//         return seminarDetails.sharedProfit
//             ? +seminarDetails.minimalPriceTicket * (seminarDetails.sharedProfit / 100)
//             : 0;
//     };

//     const academyRevenue = () => {
//         return seminarDetails.sharedProfit
//             ? +seminarDetails.minimalPriceTicket * ((100 - seminarDetails.sharedProfit) / 100)
//             : 0;
//     };

//     return (
//         <Step
//             currentStatus={currentStatus}
//             label="Camp Details"
//             status={status}
//             step={step}
//             requestId={requestId}
//             eventType="plancamp"
//             eventId={seminarDetails.id}
//         >
//             <CardDetails
//                 isEdited={
//                     seminarDetails?.lastEdited?.includes("languages") ||
//                     seminarDetails?.lastEdited?.includes("traningStyle") ||
//                     seminarDetails?.lastEdited?.includes("targetAudience") ||
//                     seminarDetails?.lastEdited?.includes("maxGymCapacity") ||
//                     seminarDetails?.lastEdited?.includes("allowUnder18") ||
//                     seminarDetails?.lastEdited?.includes("minAttendance")
//                 }
//                 label="Camp Information"
//             >
//                 <div className="row-group justify-between">
//                     <p className="fw-500">
//                         Language
//                     </p>

//                     <p className="color-text">
//                         {seminarDetails?.languages?.length
//                             ? seminarDetails?.languages[0]
//                             : null}
//                     </p>
//                 </div>

//                 <div className="row-group justify-between">
//                     <p className="fw-500">
//                         Gi or NoGi

//                         {" "}
//                     </p>

//                     <p className="color-text">
//                         {trainingStyle.find((item) => item.key === seminarDetails?.traningStyle)?.title}
//                     </p>
//                 </div>

//                 <div className="row-group justify-between">
//                     <p className="fw-500">
//                         Open to
//                     </p>

//                     <p className="color-text">
//                         {seminarDetails?.targetAudience
//                             ? capitalize(seminarDetails?.targetAudience)
//                             : "Everyone"}
//                     </p>
//                 </div>

//                 <div className="row-group justify-between">
//                     <p className="fw-500">
//                         Max Gym Capacity
//                     </p>

//                     <p className="color-text">
//                         {seminarDetails?.maxGymCapacity}
//                     </p>
//                 </div>

//                 <div className="row-group justify-between">
//                     <p className="fw-500">
//                         Under the Age of 18
//                     </p>

//                     <p className="color-text">
//                         {seminarDetails?.allowUnder18
//                             ? "Allowed"
//                             : "Not  Allowed"}
//                     </p>
//                 </div>

//                 <div className="row-group justify-between">
//                     <p className="fw-500">
//                         Minimum Attendance
//                     </p>

//                     <p className="color-text">
//                         {seminarDetails?.minAttendance}
//                     </p>
//                 </div>
//             </CardDetails>

//             {
//                 seminarDetails?.sharedProfit
//                     ? (
//                         <CardDetails
//                             label="Price Information"
//                             isEdited={
//                                 seminarDetails?.lastEdited?.includes("sharedProfit") ||
//                             seminarDetails?.lastEdited?.includes("minimalPriceTicket")
//                             }
//                         >
//                             <div className="row-group justify-between">
//                                 <p className="fw-500">
//                                     Shared Profit
//                                 </p>

//                                 <p className="color-text">
//                                     {seminarDetails?.sharedProfit}
//                                 </p>
//                             </div>

//                             <div className="row-group justify-between">
//                                 <p className="fw-500">
//                                     Min Ticket Price
//                                 </p>

//                                 <p className="color-text">
//                                     {seminarDetails?.minimalPriceTicket}
//                                 </p>
//                             </div>

//                             <hr className="hr my-3" />

//                             <div className="row-group justify-between">
//                                 <p className="color-text">
//                                     Instructor Cut:
//                                 </p>

//                                 <p className="fw-500">
//                                     {instructorCut()}
//                                 </p>
//                             </div>

//                             <div className="row-group justify-between">
//                                 <p className="color-text">
//                                     Academy Potential Revenue:
//                                 </p>

//                                 <p className="fw-500">
//                                     {academyRevenue()}
//                                 </p>
//                             </div>
//                         </CardDetails>
//                     )


//                     : (
//                         <CardDetails
//                             label="Price range for seminar"
//                             isEdited={
//                                 seminarDetails?.lastEdited?.includes("priceFrom") ||
//                             seminarDetails?.lastEdited?.includes("priceTo")
//                             }
//                         >
//                             <div className="row-group justify-between">
//                                 <p className="fw-500">
//                                     Min price
//                                 </p>

//                                 <p className="color-text">
//                                     {seminarDetails?.priceFrom}
//                                 </p>
//                             </div>

//                             <div className="row-group justify-between">
//                                 <p className="fw-500">
//                                     Max price
//                                 </p>

//                                 <p className="color-text">
//                                     {seminarDetails?.priceTo}
//                                 </p>
//                             </div>
//                         </CardDetails>
//                     )


//             }
//         </Step>
//     );
// };
