import classNames from "classnames";
import moment from "moment";
import { what_includes } from "../../../../constants";
import {
    getImageSrc, getTeachStyle
} from "../../../../helpers/utils";
import { CardDetails } from "../CardDetails";
import { Step } from "../Step";
import { ReactComponent as CheckIcon } from "../../../../assets/img/ico-check.svg";

export const StepDesign = ({
    seminarDetails, currentStatus, status, step, requestId 
}) => {
    const teachingStyles = getTeachStyle(seminarDetails.teachingStyles).join(", ");
    const normalizeTime = (str) => str ? str.slice(3) : str

    return (
        <Step
            currentStatus={currentStatus}
            label="Design & Text"
            status={status}
            step={step}
            eventType="plancamp"
            requestId={requestId}
            eventId={seminarDetails.id}

        >
            {
                seminarDetails?.photos?.length
                    ? (
                        <div className="row gutters-form-7">
                            {
                                seminarDetails?.photos.map((photo) => (
                                    <div
                                        className="col-6"
                                        key={photo?.id}
                                    >
                                        <img
                                            className="w-full"
                                            src={getImageSrc(photo?.url, null, 256)}
                                            style={{ height: 96 }}
                                            alt={photo?.originalname}
                                        />
                                    </div>
                                )



                                )
                            }
                        </div>
                    )


                    : null
            }

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("name")}
                label="Camp Name"
            >
                <p className="color-text">
                    {seminarDetails?.name || "Empty"}
                </p>
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("summary")}
                label="Summary"
            >
                <p className="color-text">
                    {seminarDetails?.summary || "Empty"}
                </p>
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("videos")}
                label="Camp Video"
            >
                {
                    seminarDetails?.videos?.length
                        ? (
                            <div className="col-group">
                                {
                                    seminarDetails?.videos.map((item) => (
                                        <video
                                            className="w-full"
                                            key={item.id}
                                            src={getImageSrc(item.url, null, 256)}
                                        ></video>
                                    )



                                    )
                                }
                            </div>
                        )


                        : (
                            <p className="color-text mx-auto">
                                No video
                            </p>
                        )


                }
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("teachingStyles")}
                label="Teaching Styles"
            >
                {
                    teachingStyles
                        ? (
                            <p className="color-text">
                                {teachingStyles}
                            </p>
                        )


                        : (
                            <p className="color-text mx-auto">
                                No Teaching Styles
                            </p>
                        )


                }
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("hotelLink")}
                label="Hotel Link"
            >
                {
                    seminarDetails.hotelLink?.length
                        ? seminarDetails.hotelLink.map((item, index) => (
                            <p
                                key={index}
                                className="color-text"
                            >
                                {item}
                            </p>
                        )



                        )
                        : (
                            <p className="color-text mx-auto">
                                No hotel link
                            </p>
                        )


                }
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("schedules")}
                label="Camp Schedules"
            >
                <div className="col-group gap-24">
                    {
                        seminarDetails?.schedules?.length
                            ? seminarDetails?.schedules.map((item, index, arr) => (
                                <div
                                    className={classNames("col-group gap-16")}
                                    key={index}
                                >
                                    <div className="row-group gap--xs">
                                        <span className="camp-schedule__number-day camp-schedule__number-day--small">
                                            Day{index + 1}
                                        </span>

                                        <p className="fw-500">
                                            {moment(item?.date).format("MMM DD")}
                                        </p>
                                    </div>

                                    <div className="col-group gap-16">
                                        <div className="row-group justify-between gap--xs">
                                            <p className="color-text">
                                                {item?.name}
                                            </p>

                                            <p className="color-text">
                                                {normalizeTime(item?.start)}
                                            </p>

                                            {/* <p className='color-text'>{item?.start}
                                                {" "}
                                                -
                                                {" "}
                                                {item?.end}
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            )



                            )
                            : (
                                <p className="color-text mx-auto">
                                    No camp schedule
                                </p>
                            )


                    }
                </div>
            </CardDetails>

            <CardDetails
                isEdited={seminarDetails?.lastEdited?.includes("whatIncludes")}
                label="Whats Included"
            >
                <div className="col-group gap-16">
                    {
                        seminarDetails.whatIncludes?.length
                            ? what_includes.map((item, index) => (
                                <div
                                    key={index}
                                    className="row-group justify-between"
                                >
                                    <p className="row-group gap--xs">
                                        <img
                                            className="ico ico-16"
                                            src={item.imgSrc}
                                            alt="ico"
                                        />

                                        <span className="fw-500">
                                            {item.label}
                                        </span>
                                    </p>

                                    {
                                        seminarDetails.whatIncludes.find((inc) => inc.id === item.id) && (
                                            <span className="ico color-secondary">
                                                <CheckIcon />
                                            </span>
                                        )}
                                </div>
                            )



                            )
                            : (
                                <p className="color-text mx-auto">
                                    No included link
                                </p>
                            )


                    }
                </div>
            </CardDetails>

            <h4 className="text-14 italic">
                Camp FAQ
            </h4>

            {
                seminarDetails?.faq?.length
                    ? seminarDetails?.faq.map((item, index, arr) => (
                        <CardDetails
                            key={index}
                            label={item.question}
                        >
                            <p className="color-text">
                                {item.answer}
                            </p>
                        </CardDetails>
                    )



                    )

                    : (
                        <CardDetails>
                            <p className="color-text mx-auto">
                                No camp FAQ
                            </p>
                        </CardDetails >
                    )


            }
        </Step >
    );
};
