export const explanations = {
    minimumAttendance: "Seminars need a minimum number of attendees to be confirmed. However, instructors and academies can choose to proceed with the seminar even if the requirement isn't met.",
    sharedProfit: "With shared profit, the total revenue from ticket sales will be divided between the instructor and the academy, according to the agreed-upon percentage share.",
    priceRangeInstructor: "The price range indicates the approximate amount you expect to earn from the seminar.",
    priceRangeAcademy: "The price range indicates the approximate amount you're willing to offer for an instructor.",
    priceRangePendingAcademy: "The price range indicates the approximate amount the academy is willing to offer for an instructor.",
    priceRangePendingInstructor: "The price range indicates the approximate amount the instructor wants to charge for the seminar.",
    locationAvailability: "When you set your location availability, you’ll be able to choose exactly where you can receive seminar requests and invitations from.",
    dateInterval: "Setting a date interval allows you to select a range of dates when you're available to hold a seminar.",
    specificDates: "Setting a specific date means you’re available for a seminar only on that day. If you want the seminar to last two days, you can add an additional day.",
    inviteInstructor: "If you’d like to collaborate with other instructors on a seminar, you can invite them here.",
    introductionVideo: "Uploading an introduction video gives you the chance to showcase who you are and explain to academies why they should book you for a seminar.",
    verifyFighterID: "Before planning any seminars, you'll need to verify your account by uploading a fighter ID.",
    approxSeminarPrice: "Approximate Seminar Price refers to the typical fee you charge for a seminar.",
    maxGymCapacity: "Max Gym Capacity is the maximum number of people allowed to attend a seminar at your academy.",
    bookingTerms: "In 'Setup Booking Specific Terms,' you can set your own terms or rules for the seminar that the platform does not have authority over."
};
